import PrivacyPolicy from 'modules/PrivacyPolicy';
import { Button, Modal, ModalBody } from 'reactstrap';
import "./styles/ModalPrivacyPolicy.scss"

function ModalPrivacyPolicy({ toggle, toggable, checkCheckboxPrivacyPolicy }) {

  const handleClickInAcceptTerms = () => {
    checkCheckboxPrivacyPolicy(true);
    toggable();
  }

  return (
    <Modal isOpen={toggle} toggle={toggable} centered size='xl' className='container-privacy-policy' backdrop="static">
      <ModalBody className='p-5 container-privacy-policy' style={{ height: "700px" }}>
        <PrivacyPolicy />
        <div className='d-flex justify-content-center mt-5'>
          <Button
            className='btn btn-secondary px-4'
            style={{ borderRadius: "20px" }}
            onClick={handleClickInAcceptTerms}
          >
            Aceptar
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default ModalPrivacyPolicy;