import React from "react";

const LogoGerflor = ({ classname }) => {
  return (
    <svg
      id="Capa_1"
      data-name="Capa 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1264.21 413.16"
      width={200}
    >
      <path
        className={classname}
        d="M908.44,531.89l-22.29,17.5c.06.33.11.66.17,1,1.35.11,2.71.31,4.06.31h144.14q115.63,0,231.28-.1c4.31,0,5.23,1.21,5.15,5.34-.28,15.27-.21,30.56-.06,45.84,0,3.53-.91,5.23-4.63,4.71a21.43,21.43,0,0,0-2.89,0H18.59c-10.39,0-10.4,0-10.39-10.41,0-13.35.15-26.71-.09-40.06-.07-4,.82-5.64,5.19-5.4,6.73.38,13.49.1,20.23.1H852.87c-.78-1.91-1.21-3.26-1.85-4.48C843,531,835.18,515.73,830.6,499c-5-18.34-6.59-37.09-6.61-56q-.11-115.63-.15-231.28c0-4,1.2-5.09,5.14-5,12.39.25,24.78.06,37.17.11,8.71,0,12.21,3.55,12.22,12.35,0,73.38.16,146.75-.08,220.13-.09,30.36,6.22,58.69,23.72,84C904,526.1,906.1,528.77,908.44,531.89Z"
        transform="translate(-7.89 -193.42)"
      />
      <path
        className={classname}
        d="M297.83,244.55C287.41,255.64,278,266,268.14,276c-4.67,4.71-11.5,4.3-17.33-.13-11-8.36-22.34-16-35.38-20.9-64.68-24.21-133.61,13.44-147.06,77.13-9.78,46.28,7,91.74,47.42,117.24,23,14.49,48.12,19.72,75.09,15.62a129.51,129.51,0,0,0,51.8-19.28,6.43,6.43,0,0,0,3.35-6c-.1-16.66-.15-33.32,0-50,0-3.65-1.24-4.74-4.8-4.72-20.24.13-40.48.08-60.72.07-8,0-11-2.9-11-10.86,0-12.53.09-25.06-.07-37.59,0-3.27,1-4.21,4.28-4.2,38.42.1,76.83.05,115.24.08,9.29,0,12.87,3.42,12.89,12.62.1,40.47.08,80.95.17,121.43,0,6.08-2.5,10.23-7.65,13.54-21.13,13.62-42.94,25.79-67.24,32.83a186.51,186.51,0,0,1-74.33,6.42C111.49,514.42,76.54,497,48.57,466.21c-20.41-22.46-32.94-49-38-78.88C4.12,348.68,9,311.59,27.9,276.94c16.6-30.44,41-52.79,72.38-67.51,28.57-13.41,58.66-18,90.05-15.26,28.68,2.5,55.29,10.87,78.9,27.5C279.06,228.59,288.06,236.68,297.83,244.55Z"
        transform="translate(-7.89 -193.42)"
      />
      <path
        className={classname}
        d="M378.34,428.83c2.59,10.82,7.5,19.11,14.81,25.88a60.56,60.56,0,0,0,80.38,1.85c4.81-4,8.85-9,13.4-13.37.86-.83,2.83-1.66,3.61-1.24C505.31,449.88,520,458,534.9,466.21a16.21,16.21,0,0,1-1.23,2.26c-18.08,22.36-40.07,39.19-68.09,47.11C412,530.72,346.8,507.66,324.41,445.37c-21.55-59.95,13.07-127,74.35-143.88,16.94-4.66,34.06-6.66,51.42-3.68a112.84,112.84,0,0,1,58.68,29.05,108.17,108.17,0,0,1,33.3,59.5c2.06,10.45,2.48,21.28,3,32,.31,6.59-4.74,10.52-12.15,10.52q-43.37,0-86.73,0H378.34Zm101.75-47.14c-.85-16.68-26.93-35.53-49.51-36-23.78-.49-48.49,17-50.39,36Z"
        transform="translate(-7.89 -193.42)"
      />
      <path
        className={classname}
        d="M1016.82,296.51c27.41.24,54.83,8.4,77.67,28.87,20.92,18.75,33.42,42.25,36.15,70.24,4.36,44.72-12.19,80.37-49.24,105.7-23.51,16.06-50.31,20.66-78.41,18.42-22.16-1.76-42.6-8.62-60.45-22-23.35-17.51-37.71-40.8-42.86-69.58-9-50.41,16.32-99.29,62.67-120.69C978.7,299.91,996.06,296.65,1016.82,296.51Zm58,111.79c-.16-34-26.35-60-60.36-59.95-33.73.08-59.9,26.31-59.88,60,0,34.07,26.5,60.39,60.53,60.18C1048.75,468.33,1075,441.87,1074.82,408.3Z"
        transform="translate(-7.89 -193.42)"
      />
      <path
        className={classname}
        d="M746.68,519.44c-1.67.13-2.88.3-4.08.3-12.25,0-24.5,0-36.75,0-9.76-.06-13.57-3.86-13.57-13.5q0-115,0-230c0-12.85,1.66-25.49,6.3-37.63,7.11-18.6,19.87-31.54,38.73-37.91,23.42-7.91,46.82-6.94,69.9,1.7,5.26,2,5.21,2.26,3.07,7.3-4.4,10.38-8.88,20.73-13.28,31.11-2.07,4.89-5.75,7.29-11,7.06a45,45,0,0,1-7.73-1.08c-16.3-3.6-29.91,5.84-31.29,22.46-.78,9.3-.22,18.71-.33,28.06,0,3,2,2.76,3.95,2.77,13.21,0,26.43.19,39.64-.09,4.13-.08,5.44.91,5.34,5.19-.29,12-.06,23.95-.08,35.92,0,8.16-2.76,10.86-10.89,10.87-11,0-22,.2-33-.07-4.12-.1-5,1.33-5,5.19q.17,77.82.08,155.65Z"
        transform="translate(-7.89 -193.42)"
      />
      <path
        className={classname}
        d="M614.17,307.19c.49-.24,1.2-.34,1.41-.72,4-7.34,11.25-9.15,18.54-9.72,16-1.25,31.59.54,46.41,7.11a10.44,10.44,0,0,1,1.41,1c-1.29,3-2.56,6.15-3.92,9.23-4.49,10.2-9,20.41-13.51,30.58-3,6.59-7.84,8.76-14.8,6.73-12.86-3.76-22.68-1.36-29.14,7.24-4.05,5.4-5.33,11.61-5.32,18.27q.07,67.73,0,135.44a25.3,25.3,0,0,0,0,2.89c.4,3.31-.8,4.62-4.33,4.57-12.8-.17-25.61,0-38.41-.06-8.1,0-11.95-3.78-12-11.87q-.12-42.53-.09-85.06c0-40.61,0-81.21-.1-121.82,0-4.08,1.26-5.1,5.16-5,10.86.24,21.74.08,32.62.07C609.72,296.05,610.72,296.74,614.17,307.19Z"
        transform="translate(-7.89 -193.42)"
      />
      <path
        className={classname}
        d="M1203.21,307.92c5.61-10,14.89-11.32,24.39-11.5,13.42-.25,26.62,1.27,39.22,6.43,2.82,1.15,3.88,2.12,2.4,5.36-5.54,12.13-10.73,24.42-16.12,36.61-2.76,6.23-7.91,8.5-14.53,6.63a27.77,27.77,0,0,0-3.61-.83c-20.1-2.93-31.23,6.76-31.23,27.17,0,45.56,0,91.12.09,136.67,0,4.07-.88,5.48-5.21,5.38q-18.57-.44-37.16,0c-7.28.17-12.42-4.43-12.39-12.42q.32-103.44,0-206.87c0-3.79,1.24-4.62,4.73-4.56,10.87.18,21.74.06,32.62.06C1198.48,296.05,1198.9,296.33,1203.21,307.92Z"
        transform="translate(-7.89 -193.42)"
      />
      <path
        className={classname}
        d="M1246.23,520.73c-14.86-.2-25.49-11.19-25.32-26.17.17-14.22,11.61-25,26.39-24.77a25.48,25.48,0,0,1-1.07,50.94ZM1224.43,495a21.79,21.79,0,0,0,22.06,22.31,22,22,0,1,0,.16-43.95C1234.11,473.33,1224.46,482.73,1224.43,495Z"
        transform="translate(-7.89 -193.42)"
      />
      <path
        className={classname}
        d="M1236.78,481.84c5.64,0,10.83-.21,16,.07,3.28.18,5.16,2.48,5.51,5.71.36,3.39-.51,6.63-4,7.74s-3,2.66-1.58,5.08c1.67,2.79,3.14,5.71,4.7,8.57l-1,1.14c-1.28-.88-2.94-1.49-3.76-2.68a63.81,63.81,0,0,1-4.87-8.6,4,4,0,0,0-5.43-2.3c-1,.4-1.79,2.61-1.95,4.07-.3,2.84-.08,5.74-1,9.5-.88-.79-2.49-1.56-2.51-2.37C1236.71,499.15,1236.78,490.53,1236.78,481.84Zm3.91,3.24c.06,2.85-2.23,6.33,1.79,7.45,2.76.78,6,.3,8.94-.31,1.32-.27,3.37-2.44,3.18-3.33-.32-1.45-2.07-3.41-3.46-3.64C1247.82,484.72,1244.35,485.08,1240.69,485.08Z"
        transform="translate(-7.89 -193.42)"
      />
    </svg>
  );
};

export default LogoGerflor;
