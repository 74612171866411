/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import PhotoSwipeLightbox from "photoswipe/lightbox";

const usePhotoswipe = ({ galleryID }) => {
  useEffect(() => {
    let lightbox = new PhotoSwipeLightbox({
      gallery: "#" + galleryID,
      children: "a",
      pswpModule: () => import("photoswipe"),
    });
    lightbox.init();

    return () => {
      lightbox.destroy();
      lightbox = null;
    };
  }, []);
};

export default usePhotoswipe;
