import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import Select from "react-select";

const selectStlyes = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "transparent",
    padding: "2px",
    border: "2px solid white",
    color: "white",
    ':hover': {
      border: "2px solid white",
    }
  }),
  placeholder: (provided) => ({
    ...provided,
    color: 'white'
  }),
}


const SelectCommon = ({
  labelClassName,
  label,
  placeholder,
  name,
  control,
  disabled = false,
  isSearchable = false,
  isMulti = false,
  options = [],
}) => (

  <>
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange: handleOnChangeForm, ...field } }) => (
        <Select
          styles={selectStlyes}
          {...field}
          options={options}
          placeholder={placeholder}
          isDisabled={disabled}
          isSearchable={isSearchable}
          isMulti={isMulti}
          onChange={handleOnChangeForm}
        />
      )}
    />
  </>
);

SelectCommon.propTypes = {
  labelClassName: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  disabled: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isMulti: PropTypes.bool,
};

export default SelectCommon;
