import React from 'react'
import PropTypes from "prop-types";
import { PRODUCTS_AND_SERVICES } from './constants/productsAndServices';
import "./styles/ProductPortafolioAndServices.scss"

const ProductPortofolioAndServices = ({ id }) => {
  return (
    <div id={id} className="product-container p-md-5" style={{ height: "fit-content" }}>
      <div className="container p-4 p-md-5 mt-5">
        <h1 className="col-12 col-md-7 fw-bold mb-5 mt-5 mt-md-0 text-dim-gray">CONOCE NUESTRO PORTAFOLIO DE PRODUCTOS Y SERVICIOS:</h1>
        <div className='py-5'>
          {
            PRODUCTS_AND_SERVICES.map(({ title, description, img, link }) => (
              <div className="d-lg-flex" key={`key_${title}`}>
                <div className="col-12 col-lg-4 pe-3 py-3" style={{ height: "300px" }}>
                  <a href={link} target='_blank'><img src={img} alt='' className='img-fluid h-100' width={340} height={270} /></a>
                </div>
                <div className="col-12 col-lg-8 px-lg-5 mt-5 mt-lg-0 mb-5 mb-md-0 mb-lg-0 divider">
                  <h1 className="fw-bold mb-4 subtitle-color">{title}</h1>
                  <p className='text-dim-gray'>{description}</p>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}

ProductPortofolioAndServices.propTypes = {
  id: PropTypes.string.isRequired,
};

export default ProductPortofolioAndServices