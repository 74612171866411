import { useState } from "react";
import Slider from "react-slick";
import { LOGOS } from "./constants/logos";
import { SETTINGS_LOGOS_CAROUSEL } from "./constants/settingsCarousel";
import "./styles/LogosCarousel.scss";

const LogosCarousel = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleAfterChange = (currentIndex) => {
    setCurrentSlide(currentIndex);
  };

  return (
    <div className="ps-3 pt-4 p-md-5 container col-12">
      <Slider {...SETTINGS_LOGOS_CAROUSEL} afterChange={handleAfterChange}>
        {LOGOS.map((item, index) => {
          return (
            <div
              key={item.id}
              id={item.id}
              onClick={() => setCurrentSlide(index)}
              className="text-center"
            >
              {item.src("default")}
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default LogosCarousel;
