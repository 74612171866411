import gql from "graphql-tag";

const createStoreLead = gql`
  mutation storeLead($input: StoreLeadInput!) {
    storeLead(input: $input) {
      status
      clientMutationId
    }
  }
`;

export default createStoreLead;
