import React from "react";
import Slider from "react-slick";
import { Button } from "reactstrap";
import "./styles/SimpleSlider.scss"
import { Link } from "react-scroll";

export default function SimpleSlider({ items }) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <Slider {...settings} arrows={false} className="bg-secondary simple-slider">
      {
        items.map(({ description, bannerImg, btn, btnScroll }) => (
          <div className="container-images">
            <img src={bannerImg} alt="" className="w-100 image-overshadow" />
            <div className="mt-3 slider--first_banner-description-content">
              <h1 className="col-10 col-md-8 fw-bold text-white text-uppercase slider--first_banner-description-paragraph">
                {description}
              </h1>
              {btn && <a
                href={btn.href}
                target="_blank"
                rel="noreferrer"
              >
                <Button color="light" outline className="mt-4 slider--banner_more-information-btn">
                  {btn.text}
                </Button>
              </a>}
              {btnScroll && <Link
                activeClass="active"
                to={btnScroll.id}
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                <Button color="light" outline className="mt-4 slider--banner_more-information-btn">
                  {btnScroll.text}
                </Button>
              </Link>}
            </div>
          </div>
        ))
      }
    </Slider>
  );
}