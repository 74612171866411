import ErrorMessage from "components/ErrorMessage";
import React from "react";
import ModalPrivacyPolicy from "../ModalPrivacyPolicy";
import useToggle from "hooks/useToggle";
import classNames from "classnames/bind";
import { useState } from "react";
import "./styles/PrivacyPolicyCheckbox.scss";

const PrivacyPolicyCheckbox = ({ register, errors }) => {
  const [acceptPolicy, setAcceptPolicy] = useState(false);
  const [toggle, toggable] = useToggle();

  return (
    <div>
      <input
        className={classNames({
          "step-form--field-check--controlled me-2": true,
          "step-form--field-check--controlled-checked": acceptPolicy,
        })}
        checked={acceptPolicy}
        id="privacyPolicy"
        onClick={toggable}
        type="checkbox"
        {...register("privacyPolicy")}
      />
      <label className="text-white cursor-ponter" htmlFor="privacyPolicy">
        He leído y acepto la
        <span className="underlined-private-policy ms-2 text-white">
          Política de Privacidad
        </span>
      </label>

      <ErrorMessage color="white">{errors.privacyPolicy?.message}</ErrorMessage>

      <ModalPrivacyPolicy
        toggle={toggle}
        toggable={toggable}
        checkCheckboxPrivacyPolicy={setAcceptPolicy}
      />
    </div>
  );
};

export default PrivacyPolicyCheckbox;
