import LogoGerflor from "../components/LogoGerflor"
import LogoSpradling from "../components/LogoSpradling"
import LogoVescom from "../components/LogoVescom"

export const LOGOS = [
  {
    id: 0,
    alt: "Image 1",
    src: (classname) => <LogoSpradling classname={classname} />
  },
  {
    id: 1,
    alt: "Image 2",
    src: (classname) => <LogoGerflor classname={classname} />
  },
  
  {
    id: 2,
    alt: "Image 3",
    src: (classname) => <LogoVescom classname={classname} />
  },
  {
    id: 0,
    alt: "Image 1",
    src: (classname) => <LogoSpradling classname={classname} />
  },
  {
    id: 1,
    alt: "Image 2",
    src: (classname) => <LogoGerflor classname={classname} />
  },
  
  {
    id: 2,
    alt: "Image 3",
    src: (classname) => <LogoVescom classname={classname} />
  },
]