const MainInfo = () => (
  <div className="container">
    <div className="row justify-content-end">
      <div className="col-md-1 gy-2 d-flex flex-column align-items-end">
        <span className="fw-bold">Ubicación:</span>
        <span className="text-dim-gray">Bogotá</span>
        <span className="text-dim-gray">Colombia</span>
      </div>
      <div className="col-md-1 gy-2 d-flex flex-column align-items-end">
        <span className="d-block fw-bold">Año:</span>
        <span className="text-dim-gray">2022</span>
        <span className="d-block fw-bold mt-2">Sector:</span>
        <span className="text-dim-gray">Salud</span>
      </div>
      <div className="col-md-2 gy-2 d-flex flex-column align-items-end">
        <span className="fw-bold">Productos:</span>
        <span className="text-dim-gray">Pisos Vinílicos,</span>
        <span className="text-dim-gray">Tapicerías Vinílicas,</span>
        <span className="text-dim-gray">Wallcovering,</span>
        <span className="text-dim-gray">Alfombras Modulares</span>
      </div>
      <div className="col-md-2 gy-2 d-flex flex-column align-items-end text-end">
        <span className="fw-bold">Cliente:</span>
        <span className="text-dim-gray">Construcciones Planificadas,</span>
        <span className="text-dim-gray">
          Taller de Arquitectura Rafael de la Hoz
        </span>
      </div>
    </div>
  </div>
);

export default MainInfo;
