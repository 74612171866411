import PropTypes from "prop-types";
import { useNavigate, useParams } from "react-router-dom";
import "./styles/thankYou.scss";

const ThankYou = ({ title }) => {
  const navigate = useNavigate();
  const { project } = useParams();

  return (
    <div className="container p-4 m-md-5 p-md-5">
      <div className="row my-5 py-5">
        <div className="col">
          <h1 className="text-capitalize fw-bold my-5">
            {!project ? title : `Proyecto: ${project}`}
          </h1>
          <h3 className="description my-2">
            Gracias por llenar nuestro formulario, recibimos su información
            exitosamente. Nuestro equipo se estará comunicando contigo muy
            pronto.
          </h3>
          <button
            className="btn btn-dark btn-lg mt-4"
            onClick={() => navigate(-1)}
          >
            volver
          </button>
        </div>
      </div>
    </div>
  );
};

ThankYou.propTypes = {
  title: PropTypes.string.isRequired,
};

export default ThankYou;
