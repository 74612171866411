import PropTypes from "prop-types";
import Slider from "react-slick";
import { DESIGN_SECTORS } from "./constants/sectors";
import { SETTINGS } from "./constants/settingsSlider";
import "./styles/DesignReferences.scss";

const DesignReferences = ({ id }) => {
  return (
    <div id={id} className="design-reference-container container pt-5">
      <h1 className="p-3 p-md-5 fw-bold mb-0 text-dim-gray">
        DESDE EL AÑO 2003 HEMOS HECHO <br></br>
        REALIDAD IDEAS QUE SE HAN CONVERTIDO <br></br>
        EN REFERENTES DEL DISEÑO <br></br> EN SECTORES COMO:
      </h1>
      <div className="p-3 p-md-5 mt-0 col-12 text-dim-gray">
        <Slider {...SETTINGS} className="slider-sectors">
          {DESIGN_SECTORS.map(({ id, title, description, src, alt }) => (
            <div key={id}>
              <div className="sector-title-container d-flex align-items-end">
                <h1 className="header fw-bold">{title}</h1>
              </div>
              <img
                className="design-sector-img bg-secondary col-12"
                src={src}
                alt={alt}
              />
              <p className="description mt-5 fs-5 col-12">{description}</p>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

DesignReferences.propTypes = {
  id: PropTypes.string.isRequired,
};

export default DesignReferences;
