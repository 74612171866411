import banner from "./img/banner-1.jpg";

export const NAV_ITEMS = ["CTIC", "descripción", "productos", "contacto"];

export const SLIDES = [
  {
    id: "slide-cetic-1",
    imageThumb: banner,
    title: "CTIC",
    description:
      "Centro de tratamiento e\ninvestigación sobre Cáncer\nLuis Carlos Sarmiento Angulo",
    width: 1920,
    height: 1200,
  },
];

export const GLOBAL_SITE_TAG = "GTM-58N2H3M4"
