import officeImg from "components/SimpleSlider/img/img_banner.jpeg"
import cticBannerImg from "../../Projects/Ctic/img/banner-1.jpg"

export const SLIDER_ITEMS = [
  {
    description: "UN EQUIPO PROFESIONAL PARA HACER REALIDAD TUS IDEAS",
    bannerImg: officeImg,
    btnScroll: {
      id: "Contáctanos",
      text: "Conocer más"
    }
  },
  {
    description: "Conoce nuestra participación en el CTIC en Bogotá",
    bannerImg: cticBannerImg,
    btn: {
      text: "Conocer el Proyecto",
      href: "https://www.calypsoproyectos.com/proyectos/ctic"
    }
  }
]