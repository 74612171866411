import { BrowserRouter, Routes, Route } from "react-router-dom"
import { ROUTES } from "./constants"

const RouteManager = () => (
  <BrowserRouter>
    <Routes>
      {Object.keys(ROUTES).map((key, index) => {
        const route = ROUTES[key]
        return <Route key={index} exact={route.exact} path={route.path} element={route.element} />
      })}
    </Routes>
  </BrowserRouter>
)

export default RouteManager
