import PropTypes from "prop-types"
import { Link } from "react-scroll"
import classNames from "classnames/bind"
import BurgerBtn from "components/Header/components/Menu/components/BurgerBtn"
import useToggle from "hooks/useToggle"
import "./styles/navbar.scss"
import RenderObject from "./components/RenderObject"
import useMediaQuery from "hooks/useMediaQuery"
import { SM } from "consts/breakpoints"

export const Menu = ({ logo, items = [], objItems = [], isScrolled, isCtic }) => {
  const isMobile = useMediaQuery(SM);
  const [toggable, setToggable] = useToggle()

  const handleRenderArray = () =>
    items.map((item) => (
      <li className={classNames({
        "main-menu-sections__section-white": !isScrolled || isMobile,
        "main-menu-sections__section-black": isScrolled && !isMobile,
        "main-menu-sections__ctic": isCtic,
        })} key={`key_${item}`}>
        <div className="text-capitalize p-3">
          <Link
            className={classNames({
              "header-menu-button": true,
              "text-black": isScrolled || isCtic,
              "text-white": isMobile
            })}
            activeClass="header-menu-button--active"
            to={item}
            duration={100}
            offset={-50}
            smooth
            spy
          >
            {item}
          </Link>
        </div>
      </li>
    ))

  return (
    <div className="container">
      <div className="row g-0">
        <div className="col-3 col-md-12 order-md-1">
          <BurgerBtn toggable={toggable} setToggable={setToggable} />
          <div
            className={classNames({
              "header-main-menu col-12": true,
              "header-main-menu--open": toggable
            })}
          >
            {(isScrolled || isCtic) && <div className="d-md-inline">
              <img src={logo} alt="" className="d-none d-md-inline img-fluid mt-3" width={200} height={100} />
            </div>}
            <ul
              className={classNames({
                "main-menu-sections": true,
                "main-menu-sections--open": toggable,
                "main-menu-sections__section-black": isScrolled || isCtic
              })}
            >
              {!items ? <RenderObject items={objItems} /> : handleRenderArray()}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

Menu.propTypes = {
  logo: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.string),
  objItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      text: PropTypes.object,
      externalLink: PropTypes.bool,
      href: PropTypes.string
    })
  ),
  isScrolled: PropTypes.bool,
  isCtic: PropTypes.bool
}

export default Menu
