import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import scssVars from "assets/styles/_variables.scss";
import Menu from "./components/Menu";
import logoBlack from "./img/logo_black.png"
import "./styles/header.scss";

const Header = ({
  items: itemsProp,
  objItems,
  isTransparent = false,
  isSticky = true,
  isProjectsPage
}) => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    isScrolled();
  });

  const isScrolled = () => {
    if (isSticky) {
      window.onscroll = () => {
        const headerHeight =
          parseFloat(scssVars.headerHeight.substring(0, 4)) / (1 / 16);
        if (window.scrollY > headerHeight) {
          setScrolled(true);
        } else if (window.scrollY < headerHeight) {
          setScrolled(false);
        }
      };
    }
  };

  return (
    <>
      <header
        className={classNames({
          "fixed-top pt-2": isSticky,
          "header-static": !isSticky,
          "bg-transparent": isTransparent && !scrolled,
          "bg-white": isProjectsPage,
          "upholstery-glassmorphism": scrolled && !isProjectsPage,
        })}
      >
        {!itemsProp ? (
          <Menu items={null} objItems={objItems} />
        ) : (
          <Menu
            logo={logoBlack}
            isCtic={isProjectsPage} 
            isScrolled={scrolled} 
            items={itemsProp} 
            objItems={null} 
          />
        )}
      </header>
    </>
    
  );
};

Header.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string),
  objItems: PropTypes.arrayOf(PropTypes.object),
  isSticky: PropTypes.bool,
  isTransparent: PropTypes.bool,
  isProjectsPage: PropTypes.bool
};

export default Header;
