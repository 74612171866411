import leftArrow from "../../LogosCarousel/img/left-chevron.png"
import rightArrow from "../../LogosCarousel/img/right-arrow.png"

export const SETTINGS = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  arrows: true,
  nextArrow: (
    <div>
      <div className="d-none d-md-block next-slick-arrow text-black">
        <img alt="Arrow" className="ms-2" src={rightArrow} width={50} />
      </div>
    </div>
  ),
  prevArrow: (
    <div>
      <div className="prev-slick-arrow text-black">
        <img alt="Arrow" src={leftArrow} width={50} />
      </div>
    </div>
  ),
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]

};