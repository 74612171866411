import PropTypes from "prop-types";
import "../../styles/Form.scss";
import { SECTOR_FIELD_VALUES } from "./constants/sectorValues";
import "./styles/SectorsField.scss"

export default function SectorsField({ register }) {
  return (
    <select
      className="select-control col-12 border border-2 px-4 rounded bg-input text-white"
      {...register}
    >
      <>
        <option value="" className="sectors-field__options">Selecciona un sector</option>
        {SECTOR_FIELD_VALUES.map(({ label, value, id }) => (
          <option value={value} key={id} className="sectors-field__options">
            {label}
          </option>
        ))}
      </>
    </select>
  );
}

SectorsField.propTypes = {
  register: PropTypes.object.isRequired,
};
