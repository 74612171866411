import recepcionOficinasMedicas from "./assets/img/recepcion-oficinas-medicas.jpg";
import tomaMuestras from "./assets/img/toma-de-muestras.jpg";
import oficinaFundacion from "modules/Projects/Ctic/components/Description/img/oficinas-fundacion.jpg";
import bibliotecaInfantil from "./assets/img/biblioteca-zona-infantil.jpg";
import hospitalizacionInfantil from "./assets/img/pasillo-hospitalizacion-zona-infantil.jpg";
import zonaEnfermeria from "./assets/img/zona-de-enfermeria.jpg";
import puenteConexion from "./assets/img/puente-de-conexion.jpg";
import consultorioInvestigacion from "./assets/img/consultorio-de-invertigacion.jpg";
import laboratorios from "./assets/img/laboratorios.jpg";
// For Banner
import banner_recepcionOficinasMedicas from "./assets/img/recepcion-oficinas-medicas-v2.jpg";
import banner_tomaMuestras from "./assets/img/toma-de-muestras-v2.jpg";
import banner_bibliotecaInfantil from "./assets/img/biblioteca-zona-infantil-v2.jpg";
import banner_hospitalizacionInfantil from "./assets/img/pasillo-hospitalizacion-zona-infantil-v2.jpg";
import banner_zonaEnfermeria from "./assets/img/zona-de-enfermeria-v2.jpg";
import banner_puenteConexion from "./assets/img/puente-de-conexion-v2.jpg";
import banner_consultorioInvestigacion from "./assets/img/consultorio-de-invertigacion-v2.jpg";
import banner_laboratorios from "./assets/img/laboratorios-v2.jpg";

export const GALLERY = [
  [
    {
      id: 1,
      position: "1",
      full_img: puenteConexion,
      banner_img: banner_puenteConexion,
      products: "Piso Mipolam Affinity",
      ubication: "Puente de Conexión",
    },
    {
      id: 2,
      position: "2",
      full_img: recepcionOficinasMedicas,
      banner_img: banner_recepcionOficinasMedicas,
      products: "Piso Acquafloor SCT 5.2 mm",
      ubication: "Edificio de Investigación\nSala de Espera ",
    },
    {
      id: 3,
      position: "3",
      full_img: oficinaFundacion,
      banner_img: oficinaFundacion,
      products: "Alfombra Methallic Path",
      ubication: "Edificio Hospitalización\nOficinas Fundación CTIC",
    },
    {
      id: 4,
      position: "4",
      full_img: tomaMuestras,
      banner_img: banner_tomaMuestras,
      products: "Tela Vinílica Silvertex - Mipolam Esprit",
      ubication: "Edificio de Hospitalización\nToma Muestras",
    },
    {
      id: 5,
      position: "5",
      full_img: bibliotecaInfantil,
      banner_img: banner_bibliotecaInfantil,
      products: "Piso Taralay Impression",
      ubication: "Zona Lúdica",
    },
  ],
  [
    {
      id: 6,
      position: "1",
      full_img: hospitalizacionInfantil,
      banner_img: banner_hospitalizacionInfantil,
      products: "Piso Taralay Impression",
      ubication: "Pasillo Habitaciones\nZona Pediatría",
    },
    {
      id: 7,
      position: "2",
      full_img: zonaEnfermeria,
      banner_img: banner_zonaEnfermeria,
      products: "Tela Vinílica Silvertex",
      ubication: "Edificio de Hospitalización\nSala de Espera",
    },
    {
      id: 8,
      position: "3",
      full_img: consultorioInvestigacion,
      banner_img: banner_consultorioInvestigacion,
      products: "Piso Acquafloor SCT 5.2 mm",
      ubication: "Consultorios de Investigación",
    },
    {
      id: 9,
      position: "4",
      full_img: laboratorios,
      banner_img: banner_laboratorios,
      products: "Piso Mipolam Elegance",
      ubication: "Laboratorios",
    },
  ],
];
