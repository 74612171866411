const PrivacyPolicy = () => (
  <div className="container">
    <div className="my-4">
      <h1 className="fw-bold">POLÍTICA DE PROTECCIÓN DE DATOS PERSONALES</h1>
    </div>
    <ol>
      <li>
        <div className="fw-bold">INTRODUCCIÓN</div>
        <p>
          Esta Política de Protección de Datos Personales (la “Política”) define
          los lineamientos generales para la protección y Tratamiento (según se
          define más adelante) de los datos personales de Comercializadora
          Calypso SAS y Calypso Barranquilla SAS (en adelante la “Compañía”),
          cada una individualmente considerada como Responsable del tratamiento
          de los datos personales, de conformidad con el Régimen de Protección
          el Régimen de Protección de Datos Personales, contenido en la Ley 1581
          de 2012, y el Decreto 1074 de 2015.
        </p>
      </li>
      <li>
        <div className="fw-bold">
          DENTIFICACIÓN DEL RESPONSABLE DEL TRATAMIENTO DE DATOS PERSONALES
        </div>
        <p>
          Comercializadora Calypso SAS con domicilio en la Carrera 24 No. 10-38
          en la ciudad de Bogotá, Colombia, teléfono 2473740
        </p>
        <p>
          Calypso Barranquilla SAS, con domicilio en la Calle 32 # 42-48 en la
          ciudad de Barranquilla, Atlántico, teléfono +53403451
        </p>
      </li>
      <li>
        <div className="fw-bold">DEFINICIONES Y PRINCIPIOS RECTORES</div>
        <p>
          <b>Autorización</b>:​ Consentimiento previo, expreso e informado del
          Titular para llevar a cabo el Tratamiento de datos personales.
        </p>
        <p>
          <b>Base de datos</b>: ​ Conjunto organizado de datos personales que
          sea objeto de tratamiento
        </p>
        <p>
          <b>Dato Personal​</b>: Cualquier información vinculada o que pueda
          asociarse a una o varias personas naturales determinadas o
          determinables.
        </p>
        <p>
          <b>Encargado del Tratamiento</b>:​ Personal natural o jurídica,
          pública o privada que por sí mismo o en asocio con otros, realice el
          Tratamiento de datos personales por cuenta del Responsable del
          Tratamiento.
        </p>
        <p>
          <b>Responsable del Tratamiento​</b>: Persona natural o jurídica,
          pública o privada que por sí misma o en asocio con otros, decida sobre
          la base de datos y/o el Tratamiento de los datos.
        </p>
        <p>
          <b>Dato público​</b>: Es el dato que no sea semiprivado, privado o
          sensible. Son considerados datos públicos, entre otros, los datos
          relativos al estado civil de las personas, a su profesión u oficio y a
          su calidad de comerciante o de servidor público. Por su naturaleza,
          los datos públicos pueden estar contenidos, entre otros, en registros
          públicos, documentos públicos, gacetas yboletines oficiales y
          sentencias judiciales debidamente ejecutoriadas que no estén sometidas
          a reserva.
        </p>
        <p>
          <b>Datos sensibles</b>:​ Se entiende por datos sensibles aquellos que
          afectan la intimidad del Titular o cuyo uso indebido puede generar su
          discriminación, tales como aquellos que revelen el origen racial o
          étnico, la orientación política, las convicciones religiosas o
          filosóficas, la pertenencia a sindicatos, organizaciones sociales, de
          derechos humanos o que promueva intereses de cualquier partido
          político o que garanticen los derechos y garantías de partidos
          políticos de oposición, así como los datos relativos a la salud, a la
          vida sexual, y los datos biométricos. Asimismo y mientras esté vigente
          la emergencia sanitaria decretada por el Gobierno Colombiano en todo
          el territorio colombiano con ocasión de la necesidad de mitigar,
          prevenir y controlar la propagación del COVID-19, la Compañía podrá
          recolectar información relacionada con el estado de salud de los
          trabajadores, estado de salud de los familiares que conviven con los
          trabajadores, temperatura corporal, signos y síntomas relacionados con
          COVID -19.
        </p>
        <p>
          <b>Transferencia</b>:​ La transferencia de datos tiene lugar cuando el
          Responsable y/o Encargado del Tratamiento de datos personales, ubicado
          en Colombia, envía la información o los datos personales a un
          receptor, que a su vez es Responsable del Tratamiento y se encuentra
          dentro o fuera del país.
        </p>
        <p>
          <b>Transmisión</b>:​ Tratamiento de datos personales que implica la
          comunicación de los mismos dentro o fuera del territorio de la
          República de Colombia cuando tenga por objeto la realización de un
          Tratamiento por el Encargado por cuenta del Responsable.
        </p>
        <p>
          <b>Titular</b>:​ Persona natural cuyos datos personales sean objeto de
          Tratamiento.
        </p>
        <p>
          <b>Tratamiento</b>:​ Cualquier operación o conjunto de operaciones
          sobre datos personales, tales como la recolección, almacenamiento,
          uso, circulación o supresión.
        </p>
      </li>
      <li>
        <div className="fw-bold">
          Principios rectores para el tratamiento de los datos personales
        </div>
        <ul>
          <li>
            <b>Principio de legalidad</b>:​ El tratamiento a que se refiere la
            Ley 1581 de 2012 es una actividad reglada que debe sujetarse a lo
            establecido en ella y en las demás disposiciones que la desarrollen.
          </li>
          <li>
            <b>Principio de finalidad</b>:​ El Tratamiento debe obedecer a una
            finalidad legítima de acuerdo con la Constitución y la Ley, la cual
            debe ser informada al Titular.
          </li>
          <li>
            <b>Principio de libertad</b>:​ El Tratamiento sólo puede ejercerse
            con el consentimiento, previo, expreso e informado del Titular. Los
            datos personales no podrán ser obtenidos o divulgados sin previa
            autorización, o en ausencia de mandato legal o judicial que releve
            el consentimiento.
          </li>
          <li>
            <b>Principio de veracidad o calidad​</b>: La información sujeta a
            Tratamiento debe ser veraz, completa, exacta, actualizada,
            comprobable y comprensible. Se prohíbe el Tratamiento de datos
            parciales, incompletos, fraccionados o que induzcan a error;
          </li>
          <li>
            <b>Principio de transparencia</b>:​ En el Tratamiento debe
            garantizarse el derecho del Titular a obtener del Responsable del
            Tratamiento o del Encargado del Tratamiento, en cualquier momento y
            sin restricciones, información acerca de la existencia de datos que
            le conciernan;
          </li>
          <li>
            <b>Principio de acceso y circulación restringida</b>:​ El
            Tratamiento se sujeta a los límites que se derivan de la naturaleza
            de los datos personales, de las disposiciones de la Ley 1581 de 2012
            y la Constitución. En este sentido, el Tratamiento sólo podrá
            hacerse por personas autorizadas por el Titular y/o por las personas
            previstas en la ley. Los datos personales, salvo la información
            pública, no podrán estar disponibles en Internet u otros medios de
            divulgación o comunicación masiva, salvo que el acceso sea
            técnicamente controlable para brindar un conocimiento restringido
            sólo a los Titulares o terceros autorizados conforme a la ley;
          </li>
          <li>
            <b>Principio de seguridad</b>:​ La información sujeta a Tratamiento
            por el Responsable del Tratamiento o Encargado del Tratamiento a que
            se refiere la ley, se deberá manejar con las medidas técnicas,
            humanas y administrativas que sean necesarias para otorgar seguridad
            a los registros evitando su adulteración, pérdida, consulta, uso o
            acceso no autorizado o fraudulento;
          </li>
          <li>
            <b>Principio de confidencialidad</b>:​ Todas las personas que
            intervengan en el Tratamiento de datos personales que no tengan la
            naturaleza de públicos están obligadas a garantizar la reserva de la
            información, inclusive después de finalizada su relación con alguna
            de las labores que comprende el Tratamiento, pudiendo sólo realizar
            suministro o comunicación de datos personales cuando ello
            corresponda al desarrollo de las actividades autorizadas en la ley
          </li>
        </ul>
      </li>
      <li>
        <div className="fw-bold">
          Tratamiento y finalidades al cual serán sometidos los datos personales
          tratados
        </div>
        <div className="fw-bold">Por “La compañía”</div>
        <p>
          Toda persona natural cuyos datos personales sean objeto de
          Tratamiento, específicamente los clientes, proveedores, candidatos a
          un cargo, empleados, contratistas y terceros (el “Titular”)
          manifiestan que la Compañía podrá directamente o a través de sus
          empleados, consultores, asesores y/o terceros encargados, recolectar,
          almacenar, administrar, procesar, suprimir, circular, transferir,
          transmitir, usar, conservar, ordenar, modificar, relacionar, evaluar,
          bloquear, destruir y, en general, procesar (el “Tratamiento”) sus
          datos personales suministrados al momento de solicitar nuestros
          productos, vincularse con la Compañía como cliente, proveedor, aliado,
          contratista o empleado, así como para transferir o transmitir sus
          datos personales con las personas naturales o jurídicas con los cuales
          la Compañía celebre contratos, convenios y/o alianzas dentro del giro
          ordinario de sus negocios, independientemente del nivel de protección
          del país receptor. La Compañía únicamente recolectará los datos
          personales del Titular que sean pertinentes y adecuados, para la
          finalidad para la cual son requeridos. No se recogerán datos
          personales sin la autorización previa, expresa e informada del
          Titular, a excepción de los casos previstos en la ley, en los que no
          es necesaria la obtención de dicha autorización.
        </p>
        <div className="fw-bold">Autorización</div>
        <p>
          La autorización para el Tratamiento de datos personales será obtenida
          por la Compañía mediante alguno de los siguientes mecanismos:
        </p>
        <ul>
          <li>
            Por escrito a través de formato de autorización para la recolección
            y tratamiento de datos personales.
          </li>
          <li>
            A través de la suscripción del formulario I-LAF-001 “Certificación
            LAFT y Habeas Data" de la Compañía.;
          </li>
          <li>
            Por medio de herramientas electrónicas y/o mensajes de datos
            dispuestos para los casos en particular;
          </li>
          <li>De forma oral, a través de la grabación de la voz;</li>
          <li>
            Mediante conductas inequívocas del titular que permitan concluir de
            forma razonable que otorgó la autorización.
          </li>
          <li>
            Se obtiene la información en los formularios de contacto, envíos
            masivos de mensajes de texto, redes sociales, ferias y diferentes
            eventos masivos de la Compañía
          </li>
        </ul>
        <div className="fw-bold">Finalidad</div>
        <p>
          Todo Tratamiento de Datos Personales, realizado por la Compañía,
          tendrá una finalidad legal y constitucional legítima. La utilización
          de los datos personales, se destinará a las actividades exclusivas
          para las cuales fueron autorizadas por el Titular. Las principales
          finalidades (las “Finalidades”) del Tratamiento por parte de la
          Compañía, son pero no se limitan a:
        </p>
        <ul>
          <li>
            Para el desarrollo de actividades comerciales, transaccionales,
            envíos masivos email marketing, mensajes de texto, audiencias
            personalizadas de Facebook y campañas de comunicación en diferentes
            canales y medios de la Compañía.;
          </li>
          <li>
            Promocionar nuestros productos y servicios, así como los de nuestros
            aliados estratégicos;
          </li>
          <li>
            Suministrar información relacionada con nuestros productos,
            servicios, ofertas, promociones, alianzas, estudios de mercado,
            concursos, contenidos, así como de nuestros aliados estratégicos;
          </li>
          <li>Evaluar la calidad de nuestros productos y servicios;</li>
          <li>Realizar estudios de mercado;</li>
          <li>
            Transferir o transmitir información a terceros con el fin de
            realizar la distribución o comercialización de productos y para
            efectos comerciales, de cartera o estadísticos.
          </li>
          <li>
            Dar cumplimiento a las obligaciones que la ley le impone a la
            Compañía. Asimismo y mientras esté vigente la emergencia sanitaria
            decretada por el Gobierno Colombiano en todo el territorio
            colombiano con ocasión de la necesidad de mitigar, prevenir y
            controlar la propagación del COVID-19, la recolección de datos
            sensibles se realizará con la finalidad de cumplir con las
            disposiciones legales emitidas por el Gobierno Nacional y las
            Autoridades Locales.
          </li>
          <li>
            Dar cumplimiento a las obligaciones adquiridas con nuestros
            clientes, proveedores, contratistas, aliados y empleados;
          </li>
          <li>
            Dar cumplimiento a las políticas de conocimiento de nuestros
            clientes personas naturales o jurídicas, contratistas, proveedores,
            aliados y empleados, así como de quienes aspiren a convertirse en
            nuestro proveedor, cliente, contratista o empleado;
          </li>
          <li>Brindar servicio al cliente;</li>
          <li>Administrar programas de lealtad o fidelización con clientes;</li>
          <li>
            Para la expedición de cotizaciones, facturas, notas de pedido y
            demás documentos como soporte de una transacción;
          </li>
          <li>Realizar pagos o giros;</li>
          <li>Verificar aspectos contables y de nómina;</li>
          <li>
            Para perfeccionar o concretar el pago de un producto o servicio
            adquirido;
          </li>
          <li>
            Para el despacho de pedidos y prestación de servicios contratados
          </li>
          <li>Atención del trámite de garantías y devolución de productos;</li>
          <li>
            Emisión de respuestas a las consultas acerca de productos y
            servicios ofrecidos, peticiones, quejas y reclamos;
          </li>
          <li>
            Realización de estudios con fines estadísticos, medición del nivel
            de satisfacción y conocimiento del cliente;
          </li>
          <li>
            Prestación de servicios de atención telefónica, cobranzas u otros de
            naturaleza similar;
          </li>
          <li>
            Adelantar el control y prevención de fraudes, lavado de activos y/o
            financiación del terrorismo.
          </li>
          <li>
            Creación de bases de datos de acuerdo con las características y
            perfiles de los titulares de información personal;
          </li>
          <li>Desarrollo de estudios de seguridad y financieros;</li>
          <li>Realizar labores de video vigilancia.</li>
          <li>
            Informar a sus empleados, colaboradores, clientes y proveedores,
            sobre el cumplimiento y cambios en los productos y servicios
            contratados o por contratar;
          </li>
          <li>
            Cualquier otra actividad de naturaleza similar a las anteriormente
            descritas que sean necesarias para desarrollar el objeto social de
            la Compañía.
          </li>
          <li>
            Consultar y reportar datos, (en caso que aplique) del titular del
            dato a centrales de información crediticia, entre otras CIFIN,
            DATACRÉDITO o a operadores de bancos de datos de información
            financiera, crediticia, comercial y provenientes de terceros países
            a que se refiere la Ley 1266 de 2008 para los fines indicados en
            dicha ley y sus normas reglamentarias o modificatorias.
          </li>
          <li>
            Para realizar labores de georreferenciación, de tal manera que
            garantice que puedo ser contactado efectiva y oportunamente por
            parte de la Compañía o por el tercero designado por la Compañía,
            para los fines propios de la relación contractual y comercial que he
            establecido con la Compañía.
          </li>
          <li>
            Adelantar todas las gestiones requeridas para realizar la cobranza
            de mis obligaciones y la recuperación de cartera tanto judicial como
            extrajudicialmente, incluida la realización de la notificación
            previa al reporte negativo que se debe hacer ante las centrales de
            información financiera y crediticia, para lo cual podrán ser
            utilizados mis datos de contacto: teléfono fijo, celular -SMS-,
            e-mail y dirección física, los cuales autorizo para que sean
            actualizados periódicamente, bien sea directamente o mediante la
            contratación de terceros que provean este servicio.
          </li>
          <li>
            Las demás finalidades autorizadas de manera expresa por el Titular
            de la información en la respectiva autorización para el tratamiento
            de datos personales.
          </li>
        </ul>
        <div className="fw-bold">Datos Personales Sensibles</div>
        <p>
          Teniendo en cuenta la definición de Dato Personal Sensible establecido
          en el numeral 2.1 de la presente política, La Compañía Tratará datos
          sensibles, únicamente cuando el Titular de los mismos autorice dicho
          Tratamiento o en los casos, en que la ley determina que no se requiere
          dicha autorización.
        </p>
        <p>
          En todo caso, cuando se pretenda obtener autorización para el manejo
          de datos sensibles, se informará cuáles de los datos que se recogerán
          se consideran como sensibles y que el Titular de los mismos no está
          obligado a autorizar su Tratamiento.
        </p>
        <div className="fw-bold">
          Almacenamiento y Protección de Datos Personales
        </div>
        <p>
          La base de datos se archiva y administra bajo responsabilidad de La
          Compañía, la cual cuenta con las medidas de seguridad necesarias para
          la conservación adecuada de los datos.
        </p>
        <p>
          Para la Compañía, los datos personales y los sistemas para su
          Tratamiento son activos esenciales para el desarrollo de sus
          actividades, por esta razón la información suministrada por los
          Titulares, se almacena de forma adecuada y segura, con niveles
          apropiados de confidencialidad, integridad y disponibilidad, con los
          cuales se garantice la protección de la información.
        </p>
        <p>
          Los Datos Personales que sean Tratados permanecerán en las Bases de
          Datos de la Compañía durante el tiempo que sea necesario para cumplir
          las Finalidades mencionadas en la Política.
        </p>
      </li>
      <li>
        <div className="fw-bold">
          Derechos de los titulares y derechos de los niños, niñas y
          adolescentes
        </div>
        <div className="fw-bold">Derechos de los Titulares</div>
        <p>
          Las Personas naturales en calidad de Titular de los datos personales y
          cuyos Datos Personales sean objeto de Tratamiento por parte de La
          Compañía, tienen los siguientes derechos, los cuales pueden ejercer en
          cualquier momento:
        </p>
        <ul>
          <li>Conocer, actualizar y rectificar sus datos personales;</li>
          <li>
            Solicitar la prueba de la autorización otorgada a la Compañía para
            el Tratamiento de sus datos personales;
          </li>
          <li>Ser informado del uso dado a sus datos personales;</li>
          <li>
            Revocar la autorización conferida para el Tratamiento de sus datos
            personales, salvo en aquellos casos que por disposición legal o
            contractual el dato deba permanecer en dichas bases de datos;
          </li>
          <li>
            Solicitar la supresión de sus datos personales, salvo en aquellos
            casos que por disposición legal o contractual el dato deba
            permanecer en las bases de datos;
          </li>
          <li>
            Consultar los datos personales que han sido objeto de tratamiento
            con ocasión a la autorización conferida a la Compañía.
          </li>
        </ul>
        <div className="fw-bold">
          Derechos de los Niños, Niñas y Adolescentes
        </div>
        <p>
          De conformidad con lo dispuesto por el Artículo 7o de la Ley 1581 de
          2012 y el artículo 12 del Decreto 1377 de 2013, La Compañía sólo
          realizará el Tratamiento de datos personales correspondientes a niños,
          niñas y adolescentes, siempre y cuando: i) Responda y respete el
          interés superior de los niños, niñas y adolescentes, ii) Asegure el
          respeto de sus derechos fundamentales. Cumplidos los anteriores
          requisitos, La Compañía deberá obtener la Autorización del
          representante legal del niño, niña o adolescente, previo ejercicio del
          menor de su derecho a ser escuchado, opinión que será valorada
          teniendo en cuenta la madurez, autonomía y capacidad para entender el
          asunto.
        </p>
      </li>
      <li>
        <div className="fw-bold">
          Deberes de la compañía como responsable del tratamiento
        </div>
        <p>
          Los Responsables del Tratamiento deberán cumplir los siguientes
          deberes, sin perjuicio de las demás disposiciones previstas en la
          presente ley y en otras que rijan su actividad:
        </p>
        <ol>
          <li>
            Garantizar al Titular, en todo tiempo, el pleno y efectivo ejercicio
            del derecho de hábeas data;
          </li>
          <li>
            Solicitar y conservar, en las condiciones previstas en la ley, copia
            de la respectiva autorización otorgada por el Titular;
          </li>
          <li>
            Informar debidamente al Titular sobre la finalidad de la recolección
            y los derechos que le asisten por virtud de la autorización
            otorgada;
          </li>
          <li>
            Conservar la información bajo las condiciones de seguridad
            necesarias para impedir su adulteración, pérdida, consulta, uso o
            acceso no autorizado o fraudulento;
          </li>
          <li>
            Garantizar que la información que se suministre al Encargado del
            Tratamiento sea veraz, completa, exacta, actualizada, comprobable y
            comprensible
          </li>
          <li>
            Actualizar la información, comunicando de forma oportuna al
            Encargado del Tratamiento, todas las novedades respecto de los datos
            que previamente le haya suministrado y adoptar las demás medidas
            necesarias para que la información suministrada a este se mantenga
            actualizada;
          </li>
          <li>
            Rectificar la información cuando sea incorrecta y comunicar lo
            pertinente al Encargado del Tratamiento;
          </li>
          <li>
            Suministrar al Encargado del Tratamiento, según el caso, únicamente
            datos cuyo Tratamiento esté previamente autorizado de conformidad
            con lo previsto en la ley;
          </li>
          <li>
            Exigir al Encargado del Tratamiento en todo momento, el respeto a
            las condiciones de seguridad y privacidad de la información del
            Titular;
          </li>
          <li>
            Tramitar las consultas y reclamos formulados en los términos
            señalados en la ley;
          </li>
          <li>
            Informar al Encargado del Tratamiento cuando determinada información
            se encuentra en discusión por parte del Titular, una vez se haya
            presentado la reclamación y no haya finalizado el trámite
            respectivo.
          </li>
          <li>
            Informar a solicitud del Titular sobre el uso dado a sus datos;
          </li>
          <li>
            Informar a la autoridad de protección de datos cuando se presenten
            violaciones a los códigos de seguridad y existan riesgos en la
            administración de la información de los Titulares.
          </li>
          <li>
            Cumplir las instrucciones y requerimientos que imparta la
            Superintendencia de Industria y Comercio.
          </li>
        </ol>
      </li>
      <li>
        <div className="fw-bold">
          Área responsable de la atención de peticiones, consultas y reclamos
        </div>
        <p>
          Las solicitudes de acceso, rectificación, actualización, supresión de
          datos o revocatoria del consentimiento o autorización otorgada para el
          tratamiento de sus datos personales deberán ser dirigidas a:
        </p>
        <p>
          <b>Comercializadora Calypso SAS</b>.​ Al correo electrónico{" "}
          <a href="mailto:habeasdata@orgcalypso.com">
            habeasdata@orgcalypso.com​
          </a>
          , o mediante comunicación dirigida al área jurídica de Calypso a la
          Carrea 24 No. 10-38 de Bogotá D.C.
        </p>
        <p>
          <b>Calypso Barranquilla SAS</b>.​ Al correo electrónico{" "}
          <a href="mailto:habeasdatabquilla@orgcalypso.com">
            habeasdatabquilla@orgcalypso.com​
          </a>
          , o mediante comunicación dirigida al área jurídica de Calypso a la
          Calle 53 # 46 – 133 de Barranquilla.
        </p>
        <p>
          Las solicitudes de consulta de los datos personales sujetos al
          tratamiento de la Compañía serán atendidas en un término máximo de
          diez (10) días hábiles, contados a partir de la fecha de recibido de
          la misma. Cuando no fuere posible atender la petición dentro de dicho
          término, se informará al interesado, expresando los motivos de la
          demora y señalando la fecha en que se atenderá su consulta, la cual en
          ningún caso podrá superar los cinco (5) días hábiles siguientes al
          vencimiento del primer término.
        </p>
        <p>
          Los requerimientos de rectificación, actualización, supresión o
          revocatoria del consentimiento serán atendidos dentro de los quince
          (15) días hábiles contados a partir del día siguiente a la fecha de su
          recepción. Cuando no fuere posible atender el requerimiento dentro de
          dicho término, se informará al interesado los motivos de la demora y
          la fecha en que se atenderá su requerimiento, el cual en ningún caso
          podrá superar los ocho (8) días hábiles siguientes al vencimiento del
          primer término.
        </p>
      </li>
      <li>
        <div className="fw-bold">
          Transferencia, transmisión y revelación de datos personales
        </div>
        <p>
          La Compañía podrá revelar a sus compañías vinculadas a nivel mundial,
          los Datos Personales sobre los cuales realiza el Tratamiento, para su
          utilización y Tratamiento conforme a esta Política de Protección de
          Datos Personales. Cuando La Compañía desee enviar o transmitir datos a
          uno o varios Encargados ubicados dentro o fuera del territorio de la
          República de Colombia, establecerá cláusulas contractuales o celebrará
          un contrato de transmisión de datos personales respectivamente de
          conformidad con lo dispuesto en el artículo 25 del Decreto No. 1377 de
          2013.
        </p>
        <p>
          Asimismo y mientras esté vigente la emergencia sanitaria decretada por
          el Gobierno Colombiano en todo el territorio colombiano con ocasión de
          la necesidad de mitigar, prevenir y controlar la propagación del
          COVID-19, la Compañía transferirá y transmitirá los datos sensibles
          relacionados con el estado de salud de los trabajadores, estado de
          salud de los familiares que conviven con los trabajadores, temperatura
          corporal, signos y síntomas relacionados con COVID -19 a las entidades
          del orden nacional y/o territorial a efectos que las mismas puedan
          implementar las medidas correspondientes para contrarrestar la
          propagación del COVID – 19.
        </p>
      </li>
      <li>
        <div className="fw-bold">Legislación aplicable</div>
        <p>
          Esta Política de Protección de Datos Personales se rige por lo
          dispuesto en la legislación vigente sobre protección de los Datos
          Personales a los que se refieren el Artículo 15 de la Constitución
          Política de Colombia, la Ley 1266 de 2008, la Ley 1581 de 2012, el
          Decreto 1377 de 2013, Decreto No. 1074 de 2015 y demás normas
          complementarias que regulen la materia.
        </p>
      </li>
      <li>
        <div className="fw-bold">Vigencia de la base de datos</div>
        <p>
          Las bases de datos que administran las Compañías en las que se
          registran datos personales tendrán una vigencia igual al tiempo en que
          se mantenga y utilice la información para las finalidades aquí
          previstas y se conservarán mientras no se solicite su supresión por el
          interesado, salvo que exista un deber legal de conservar dicha
          información.
        </p>
        <p>
          No obstante lo anterior, los datos sensibles recopilados por la
          Compañía y relacionados con el estado de salud de los trabajadores,
          estado de salud de los familiares que conviven con los trabajadores,
          temperatura corporal, signos y síntomas relacionados con COVID -19 en
          el marco de la emergencia sanitaria decretada por el Gobierno
          Colombiano en todo el territorio colombiano con ocasión de la
          necesidad de mitigar, prevenir y controlar la propagación del
          COVID-19, se administrarán y almacenarán por la Compañía por un
          término igual a la duración de la emergencia sanitaria decretada por
          el Gobierno Nacional en todo el territorio colombiano y seis (6) meses
          más. Vencido el término aquí previsto, la Compañía procederá a
          realizar el borrado seguro de la información de conformidad con el
          procedimiento previsto para tal fin.
        </p>
      </li>
      <li>
        <div className="fw-bold">Modificaciones</div>
        <p>
          Este documento podrá ser modificado por parte de la Compañía.
          Cualquier cambio sustancial en la Política se comunicará oportunamente
          a los Titulares.
        </p>
        <table className="table table-bordered table-static">
          <tbody>
            <tr>
              <th scope="row">Tipo de documento</th>
              <td>Política</td>
            </tr>
            <tr>
              <th scope="row">Proceso del que depende</th>
              <td>Legal</td>
            </tr>
            <tr>
              <th scope="row">Fecha de creación</th>
              <td>7 Dic. 2016</td>
            </tr>
            <tr>
              <th scope="row">Descripción del Cambio</th>
              <td>Actualización Temas de Georreferenciación y Cobranza</td>
            </tr>
            <tr>
              <th scope="row">Responsable de la actualización</th>
              <td>15-10-20</td>
            </tr>
            <tr>
              <th scope="row">No. Revisión</th>
              <td>04</td>
            </tr>
          </tbody>
        </table>
      </li>
    </ol>
  </div>
);

export default PrivacyPolicy;
