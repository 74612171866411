import usePhotoswipe from "hooks/usePhotoswipe";
import PropTypes from "prop-types";
import Slider from "react-slick";
import HomeSlide from "./components/HomeSlide";
import { SLIDER_OPTIONS } from "./constants";

const Banner = ({
  slides = [{}],
  align,
  targetLink,
  noDescription = false,
  galleryID = "TEST_GALERY_ID",
}) => {
  usePhotoswipe({ galleryID });
  return (
    <div className="pswp-gallery" id={galleryID}>
      <Slider {...SLIDER_OPTIONS}>
        {slides.map(({ id, ...slide }) => (
          <a
            href={slide.imageThumb}
            data-pswp-width={slide.width}
            data-pswp-height={slide.height}
            key={galleryID + "-" + id}
            target="_blank"
            rel="noreferrer"
          >
            <HomeSlide
              key={`key_${id}`}
              align={align}
              slide={slide}
              btnClassName={slide.btnClassName}
              targetLink={targetLink}
              noDescription={noDescription}
            />
          </a>
        ))}
      </Slider>
    </div>
  );
};

Banner.propTypes = {
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      imageThumb: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      link: PropTypes.string,
      buttonText: PropTypes.object,
      btnClassName: PropTypes.string,
    })
  ),
  align: PropTypes.string,
  targetLink: PropTypes.string,
  galleryID: PropTypes.string,
  noDescription: PropTypes.bool,
};

export default Banner;
