import PropTypes from "prop-types";
import Form from "./components/Form";

const Contact = ({ id }) => (
  <div id={id} className="my-4 py-4" style={{ backgroundColor: "#929292"}}>
    <div className="container my-4">
      <div className="row ps-4">
          <div className="col-12 col-md-12 col-lg-7 mb-3 mt-4">
            <h2 className="ps-3 fw-bold text-white">
              ¡Cuéntanos el proyecto que visualizas y te ayudamos a hacerlo realidad!
            </h2>
          </div>
      </div>
    </div>
    <Form />
  </div>
);

Contact.propTypes = {
  id: PropTypes.string.isRequired,
};

export default Contact;
