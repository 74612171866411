import client from "api";
import App from "app/App";
import "assets/fonts/futura/stylesheet.css";
import "assets/styles/main.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "photoswipe/style.css";
import React from "react";
import { ApolloProvider } from "@apollo/client";
import ReactDOM from "react-dom/client";
import "react-loading-skeleton/dist/skeleton.css";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
