export const COUNTRY_CODE_LATAM = ["CO", "AR", "UY", "PY", "EC", "BO", "VE", "BR", "CL"]
export const COUNTRY_CODE_MX = ["MX"]
export const COUNTRY_CODE_USA = ["US"]

export const COUNTRIES_FOR_USA = [
  { label: "Canada", value: "Q291bnRyeU5vZGU6Mzg=" },
  { label: "United States", value: "Q291bnRyeU5vZGU6MjM0" }
]

export const COUNTRIES_FOR_MEXICO = [{ label: "Mexico", value: "Q291bnRyeU5vZGU6MTU4" }]

export const COUNTRY_COLOMBIA = [{ label: "Colombia", value: "Q291bnRyeU5vZGU6NDk=" }]

export const COUNTRIES_FOR_PRANNA_LA = [
  { label: "Colombia", value: "Q291bnRyeU5vZGU6NDk=" },
  { label: "Peru", value: "Q291bnRyeU5vZGU6MTc1" }
]

export const COUNTRIES_FOR_PETFRIENDLY = [
  { label: "Colombia", value: "Q291bnRyeU5vZGU6NDk=" },
  { label: "Ecuador", value: "Q291bnRyeU5vZGU6NjM=" },
  { label: "Peru", value: "Q291bnRyeU5vZGU6MTc1" },
  { label: "Venezuela", value: "Q291bnRyeU5vZGU6MjM5" }
]

export const COUNTRIES_FOR_LATAM = [
  { label: "Anguilla", value: "Q291bnRyeU5vZGU6NQ==" },
  { label: "Antigua and Barbuda", value: "Q291bnRyeU5vZGU6NA==" },
  { label: "Argentina", value: "Q291bnRyeU5vZGU6MTA=" },
  { label: "Aruba", value: "Q291bnRyeU5vZGU6MTQ=" },
  { label: "Bahamas", value: "Q291bnRyeU5vZGU6MzI=" },
  { label: "Barbados", value: "Q291bnRyeU5vZGU6MTg=" },
  { label: "Belize", value: "Q291bnRyeU5vZGU6Mzc=" },
  { label: "Bermuda", value: "Q291bnRyeU5vZGU6Mjc=" },
  { label: "Bolivia", value: "Q291bnRyeU5vZGU6Mjk=" },
  { label: "Bonaire, Saint Eustatius and Saba", value: "Q291bnRyeU5vZGU6MzA=" },
  { label: "Brasil", value: "Q291bnRyeU5vZGU6MzE=" },
  { label: "British Virgin Islands", value: "Q291bnRyeU5vZGU6MjQw" },
  { label: "Cayman Islands", value: "Q291bnRyeU5vZGU6MTI1" },
  { label: "Chile", value: "Q291bnRyeU5vZGU6NDY=" },
  { label: "Colombia", value: "Q291bnRyeU5vZGU6NDk=" },
  { label: "Costa Rica", value: "Q291bnRyeU5vZGU6NTA=" },
  { label: "Cuba", value: "Q291bnRyeU5vZGU6NTE=" },
  { label: "Curacao", value: "Q291bnRyeU5vZGU6NTM=" },
  { label: "Dominica", value: "Q291bnRyeU5vZGU6NjA=" },
  { label: "Dominican Republic", value: "Q291bnRyeU5vZGU6NjE=" },
  { label: "Ecuador", value: "Q291bnRyeU5vZGU6NjM=" },
  { label: "El Salvador", value: "Q291bnRyeU5vZGU6MjEx" },
  { label: "Falkland Islands", value: "Q291bnRyeU5vZGU6NzI=" },
  { label: "French Guiana", value: "Q291bnRyeU5vZGU6ODA=" },
  { label: "Greenland", value: "Q291bnRyeU5vZGU6ODQ=" },
  { label: "Grenada", value: "Q291bnRyeU5vZGU6Nzg=" },
  { label: "Guadeloupe", value: "Q291bnRyeU5vZGU6ODc=" },
  { label: "Guatemala", value: "Q291bnRyeU5vZGU6OTE=" },
  { label: "Guyana", value: "Q291bnRyeU5vZGU6OTQ=" },
  { label: "Haiti", value: "Q291bnRyeU5vZGU6OTk=" },
  { label: "Honduras", value: "Q291bnRyeU5vZGU6OTc=" },
  { label: "Jamaica", value: "Q291bnRyeU5vZGU6MTEy" },
  { label: "Martinique", value: "Q291bnRyeU5vZGU6MTUx" },
  { label: "Montserrat", value: "Q291bnRyeU5vZGU6MTUz" },
  { label: "Netherlands Antilles", value: "Q291bnRyeU5vZGU6MjUy" },
  { label: "Nicaragua", value: "Q291bnRyeU5vZGU6MTY2" },
  { label: "Panama", value: "Q291bnRyeU5vZGU6MTc0" },
  { label: "Paraguay", value: "Q291bnRyeU5vZGU6MTg3" },
  { label: "Peru", value: "Q291bnRyeU5vZGU6MTc1" },
  { label: "Puerto Rico", value: "Q291bnRyeU5vZGU6MTgz" },
  { label: "Saint Barthelemy", value: "Q291bnRyeU5vZGU6MjY=" },
  { label: "Saint Kitts and Nevis", value: "Q291bnRyeU5vZGU6MTIw" },
  { label: "Saint Lucia", value: "Q291bnRyeU5vZGU6MTI5" },
  { label: "Saint Martin", value: "Q291bnRyeU5vZGU6MTQy" },
  { label: "Saint Pierre and Miquelon", value: "Q291bnRyeU5vZGU6MTgx" },
  { label: "Saint Vincent and the Grenadines", value: "Q291bnRyeU5vZGU6MjM4" },
  { label: "Sint Maarten", value: "Q291bnRyeU5vZGU6MjEy" },
  { label: "Suriname", value: "Q291bnRyeU5vZGU6MjA5" },
  { label: "Trinidad and Tobago", value: "Q291bnRyeU5vZGU6MjI3" },
  { label: "Turks and Caicos Islands", value: "Q291bnRyeU5vZGU6MjE1" },
  { label: "U.S. Virgin Islands", value: "Q291bnRyeU5vZGU6MjQx" },
  { label: "Uruguay", value: "Q291bnRyeU5vZGU6MjM1" },
  { label: "Venezuela", value: "Q291bnRyeU5vZGU6MjM5" }
]
