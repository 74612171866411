import Home from "modules/Home";
import NotFound from "modules/NotFound";
import PrivacyPolicy from "modules/PrivacyPolicy";
import Projects from "modules/Projects";
import Cetic from "modules/Projects/Ctic";
import ThankYou from "modules/ThankYou";

export const HOME = "HOME";
export const THANK_YOU_HOME = "THANK_YOU_HOME";
export const PROJECTS = "PROJECTS";
export const PROJECT_CETIC = "CETIC";
export const PRIVACY_POLICY = "PRIVACY_POLICY";
export const THANK_YOU = "THANK_YOU";
export const NOT_FOUND = "NOT_FOUND";
export const SANDBOX = "SANDBOX";

export const ROUTES = {
  [HOME]: {
    path: "/",
    exact: true,
    element: <Home />,
  },
  [THANK_YOU_HOME]: {
    path: "/gracias",
    exact: true,
    element: <ThankYou title="Calypso Proyectos" />,
  },
  [PROJECTS]: {
    path: "/proyectos",
    exact: true,
    element: <Projects />,
  },
  [PROJECT_CETIC]: {
    path: "/proyectos/ctic",
    exact: true,
    element: <Cetic />,
  },
  [THANK_YOU]: {
    path: "/proyectos/:project/gracias",
    element: <ThankYou />,
  },
  [PRIVACY_POLICY]: {
    path: "/politicas-de-privacidad",
    element: <PrivacyPolicy />,
  },
  [NOT_FOUND]: {
    path: "*",
    element: <NotFound />,
  },
};
