import Header from "components/Header";
import HeroBanner from "components/Hero";
import SimpleSlider from "components/SimpleSlider";
import VideoSection from "components/VideoSection";
import { NAV_ITEMS } from "./constants/headerLinks";
import DesignReferences from "components/DesignReferences";
import ProductPortofolioAndServices from "components/ProductPortofolioAndServices";
import LogosCarousel from "components/LogosCarousel";
import Footer from "components/Footer";
import Contact from "components/Contact";
import { SLIDER_ITEMS } from "./constants/sliderBannerItems";

const Home = () => {

  return (
    <>
      <div className="container-fluid p-0 hero-height">
        <Header isProjectsPage={false} isTransparent items={NAV_ITEMS} />
        <HeroBanner />
        <SimpleSlider items={SLIDER_ITEMS} />
      </div>
      <VideoSection id={NAV_ITEMS[0]} />
      <DesignReferences id={NAV_ITEMS[1]}/>
      <ProductPortofolioAndServices id={NAV_ITEMS[2]} />
      <LogosCarousel />
      <Contact id={NAV_ITEMS[3]} />
      <Footer />
    </>
  )
}

export default Home;
