import React from "react";

const LogoSpradling = ({ classname }) => {
  return (
    <svg
      id="Capa_1"
      data-name="Capa 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1276.85 215.09"
      width={250}
    >
      <g className={classname}>
        <path
          className="cls-2"
          d="M1263.71,313.1c0-6.56-5-8.18-10.69-8.18h-13V336h4.42V321.64h5.81l8,14.34h5.13l-7.88-14.34c4.16-.06,8.27-2.39,8.27-8.54m-14.5,4h-4.79v-7.8h7.78c3.7,0,7,.28,7,4.23,0,4.27-5.77,3.57-10,3.57"
          transform="translate(-1.57 -292.45)"
        />
        <path
          className="cls-2"
          d="M1250.66,292.45a27.74,27.74,0,1,0,27.77,27.76,27.75,27.75,0,0,0-27.77-27.76m0,51a23.19,23.19,0,1,1,23.22-23.2,23.19,23.19,0,0,1-23.22,23.2"
          transform="translate(-1.57 -292.45)"
        />
        <path
          className="cls-2"
          d="M396.52,378.7c0-49.79-32-63-76.06-63h-49.4V504.41h51.15V454.2c0-9.39-.5-18.58-.91-27.75l.54-.53L350,504.41H405.3l-38-78.49c20.35-8.11,29.26-26.52,29.26-47.22m-71.18,24.43-3.13-.32V356.45c13.92-.81,23.91,6.12,23.91,22.64,0,12.33-7.35,24-20.78,24"
          transform="translate(-1.57 -292.45)"
        />
        <path
          className="cls-2"
          d="M614.41,315.68H573.32V504.41h44.47c31.13,0,85.89-8,85.89-95,0-57.06-31.28-93.69-89.27-93.69m10,143.31V362.86c23.21,0,27.36,28.56,27.36,49.06,0,17.63-4.15,46.76-27.36,47.07"
          transform="translate(-1.57 -292.45)"
        />
        <rect
          className="cls-2"
          x="836.57"
          y="23.42"
          width="50.96"
          height="188.54"
        />
        <path
          className="cls-2"
          d="M1003.2,363.85c0,21.79,3.75,43.2,5.78,65.08l-.68.56L967.72,315.68H919.23V504.41h49V449.14c0-17.76-2.3-35.28-3.64-52.94l.61-.44,38,108.65h49V315.68h-49Z"
          transform="translate(-1.57 -292.45)"
        />
        <path
          className="cls-2"
          d="M454.36,315.68,409,504.41H461.7l4.11-24.26h34.33l3.71,24.26h52.72L516.68,315.68ZM472.7,440.11l7-43.7c1.79-11.83,3.18-23.79,4.67-35.63h.53c1.15,11.84,2.13,23.8,3.72,35.63l5.44,43.7Z"
          transform="translate(-1.57 -292.45)"
        />
        <polygon
          className="cls-2"
          points="777.55 23.22 726.62 23.22 726.62 211.96 815.79 211.96 815.79 167.5 777.55 167.5 777.55 23.22"
        />
        <path
          className="cls-2"
          d="M171.63,315.67H125.09V504.41h51V442.64c44.16,1.21,74.27-13.71,74.27-62.79,0-49.35-34.33-64.18-78.75-64.18m4.48,84.38v-43.5l3.72-.1c12.41,0,19.15,10.68,19.15,22.64,0,14.06-9,21.72-22.87,21"
          transform="translate(-1.57 -292.45)"
        />
        <path
          className="cls-2"
          d="M1218,395.3h-70.89v39.87h18.51c.42,11-3.83,27-17.36,27-19.73,0-18.79-33.09-19.62-46.48,0-14.36-1.95-57.57,21.55-57.57,8.95,0,13.91,11.8,14.44,19.26l45.79-16.94,3.57-1.3a1.64,1.64,0,0,0-.57-.84h1.25v-8.12c-22.47-31.72-56-36.71-63.35-37.43-.54,0-1.06-.13-1.59-.13-55.31,0-73,51.77-73,98.51,0,47.86,18.06,96.41,73.49,96.41,59,0,68-57.64,68-104.24Z"
          transform="translate(-1.57 -292.45)"
        />
        <path
          className="cls-2"
          d="M91.32,411.92c-5.92-8.62-14.3-15.51-25.06-23-2.74-2.23-6.42-4.83-9.19-7.77-2.82-3.12-5-6.43-5-10.54,0-7.24,7.13-11.45,13.24-11.45,11.75,0,24,10.77,30,20.25V322.21c-10.64-5.77-23.9-9.59-36.35-9.59a62.83,62.83,0,0,0-6.89.55,4.37,4.37,0,0,0-.72.06c-5.92,1.37-42.89,11.44-49.53,45.84v11.52c0,1.3-.25,2.89-.25,4.11A62.64,62.64,0,0,0,2.26,384a52.16,52.16,0,0,0,1.92,8.19c.07.34.11.62.18.93l.08-.1a55.61,55.61,0,0,0,7.18,14.62C18.26,416.6,27,423.93,37.1,431.6c3.1,2.25,6.23,4.57,8.38,7.4a14.06,14.06,0,0,1,4.07,10.5c0,8.07-8,12.39-15.1,12.39-12.94,0-25.13-10.59-32.19-21v59.05a100,100,0,0,0,38.24,7.61c35.5,0,60-27.95,60-62.83,0-14.42-3.31-24.29-9.22-32.8"
          transform="translate(-1.57 -292.45)"
        />
      </g>
    </svg>
  );
};

export default LogoSpradling;
