import PropTypes from "prop-types";
import classNames from "classnames/bind";
import Skeleton from "react-loading-skeleton";
import "./styles/home-slide.scss";

const NO_OP = () => {};

const HomeSlide = ({
  align = "left",
  slide,
  isBtnlarge = false,
  btnClassName = "btn-outline-light",
  isLinkByFunction = false,
  targetLink = "_self",
  noDescription = false,
}) => {
  return (
    <div
      id="home-slide"
      className="home-slide"
      style={{
        backgroundImage: `url(${slide && slide.imageThumb})`,
      }}
    >
      <div className={`home-slide__content home-slide__content--${align}`}>
        <div className="container">
          <div
            className={classNames({
              row: true,
              "align-items-center justify-content-center": align === "center",
            })}
          >
            <div className="col-12 col-md-6">
              <div
                className={classNames({
                  [`slide-content slide-content--${align} text-${align} text-light`]: true,
                  [`text-${slide.color}`]: !!slide.color,
                })}
              >
                <h2 className="slide-content__title">
                  {slide.title ? slide.title : <Skeleton width={200} />}
                </h2>
                {!noDescription && (
                  <p className="slide-content__description text-weight-medium">
                    {slide.description ? (
                      slide.description
                    ) : (
                      <Skeleton count={2} />
                    )}
                  </p>
                )}
                {slide.link && (
                  <a
                    className={classNames({
                      "slide-content__button btn": true,
                      "btn-lg": isBtnlarge,
                      "mt-2": align === "center",
                      [btnClassName]: btnClassName !== undefined,
                    })}
                    target={targetLink}
                    href={slide.link}
                    rel="noopener noreferrer"
                    onClick={slide.callback ? () => slide.callback() : NO_OP}
                  >
                    {slide.buttonText}
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

HomeSlide.propTypes = {
  align: PropTypes.string,
  slide: PropTypes.shape({
    imageThumb: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    link: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    buttonText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    color: PropTypes.oneOf(["light, dark"]),
    callback: PropTypes.func,
  }),
  targetLink: PropTypes.string,
  isBtnlarge: PropTypes.bool,
  btnClassName: PropTypes.string,
  isLinkByFunction: PropTypes.bool,
  noDescription: PropTypes.bool,
};

export default HomeSlide;
