import Footer from "components/Footer";
import Header from "components/Header";
import Banner from "./components/Banner";
import Contact from "./components/Contact";
import Description from "./components/Description";
import MainInfo from "./components/MainInfo";
import Products from "./components/Products";
import { GLOBAL_SITE_TAG, NAV_ITEMS, SLIDES } from "./constants";
import "./styles/cetic.scss";
import Analytics from "components/Analytics";


const Ctic = () => (
  <>
    <Analytics gtag={GLOBAL_SITE_TAG} />
    <Header isProjectsPage items={NAV_ITEMS} />
    <div className="container content--cetic mt-4 pt-4">
      <Banner slides={SLIDES} />
      <MainInfo />
    </div>

    

    <Description id={NAV_ITEMS[1]} />
    <Products id={NAV_ITEMS[2]} />
    <Contact id={NAV_ITEMS[3]} />
    <Footer onlyLogo />
  </>
);

export default Ctic;
