import { yupResolver } from "@hookform/resolvers/yup";
import ErrorMessage from "components/ErrorMessage";
import { useForm } from "react-hook-form";
import axios from "axios";
import { DEFAULT_VALUES, VALIDATION_SCHEMA } from "../../constants";
import PrivacyPolicyCheckbox from "./components/PrivacyPolicyCheckbox";
import SectorsField from "./components/SectorsField";
import "./styles/Form.scss";
import { useMutation } from "@apollo/client";
import createStoreLead from "modules/Projects/Ctic/components/Contact/components/Form/mutation";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";

const REQUEST_ENDPOINT_ZAPIER_HOME =
  "https://hooks.zapier.com/hooks/catch/9615379/3zkx4rj/";

const Form = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(VALIDATION_SCHEMA),
  });
  const [createLead] = useMutation(createStoreLead);

  const handleZap = async (data) => {
    try {
      await axios.post(REQUEST_ENDPOINT_ZAPIER_HOME, data);
    } catch (e) {
      console.log("handleZap ERROR -> ", e);
    }
  };

  const handleCreateContact = async ({ privacyPolicy, ...values }) => {
    const input = { source: "Calypso Proyectos: Home", ...values };
    const inputZap = JSON.stringify({
      ...values,
      date: new Date().toLocaleString(),
    });

    try {
      await createLead({ variables: { input } });
      await handleZap(inputZap);
      navigate("../gracias");
    } catch (e) {
      console.log("ERROR crete contact on HOME CONTACTFORM: ", e);
    }
  };

  return (
    <div className="container-fluid py-5 mt-5 mb-5 bg-form-container">
      <form
        className="form container px-5"
        onSubmit={handleSubmit(handleCreateContact)}
      >
        <div className="row">
          <div className="form-group col-12 col-md-6">
            <input
              className="form-control p-2 border border-2 px-4 bg-input"
              placeholder="Nombre"
              id="firstName"
              {...register("firstName")}
            />
            <ErrorMessage color="white">
              {errors.firstName?.message}
            </ErrorMessage>
          </div>
          <div className="form-group col-12 col-md-6">
            <input
              placeholder="Apellido"
              className="form-control p-2 border border-2 px-4 bg-input"
              id="lastName"
              {...register("lastName")}
            />
            <ErrorMessage color="white">
              {errors.lastName?.message}
            </ErrorMessage>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-12 col-md-6">
            <input
              placeholder="Email"
              className="form-control p-2 border border-2 px-4 bg-input"
              id="email"
              {...register("email")}
            />
            <ErrorMessage color="white">{errors.email?.message}</ErrorMessage>
          </div>
          <div className="form-group col-12 col-md-6">
            <input
              placeholder="Teléfono"
              className="form-control p-2 border border-2 px-4 bg-input"
              id="phone"
              {...register("phone")}
            />
            <ErrorMessage color="white">{errors.phone?.message}</ErrorMessage>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-12 col-md-6">
            <input
              placeholder="Puesto de trabajo"
              className="form-control p-2 border border-2 px-4 bg-input"
              id="jobPosition"
              {...register("jobPosition")}
            />
            <ErrorMessage color="white">
              {errors.jobPosition?.message}
            </ErrorMessage>
          </div>
          <div className="form-group col-12 col-md-6">
            <SectorsField register={register("sector")} />
            <ErrorMessage color="white">{errors.sector?.message}</ErrorMessage>
          </div>
        </div>
        <div className="row">
          <div className="col-12 form-group">
            <textarea
              placeholder="Comentario"
              className="form-control p-2 border border-2 px-4 bg-input"
              id="message"
              {...register("message")}
            />
            <ErrorMessage color="white">{errors.message?.message}</ErrorMessage>
          </div>
        </div>
        <div className="row">
          <div className="col-12 form-group">
            <PrivacyPolicyCheckbox register={register} errors={errors} />
            <Button color="light" outline className="mt-3 slider--banner_more-information-btn fs-form-btn" type="submit">
              {!isSubmitting ? "Enviar" : "Enviando..."}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Form;
