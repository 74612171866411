import eductation from "../img/education.jpg";
import corporate from "../img/corporate.jpg";
import salud from "../img/salud.jpg";
import residencial from "../img/residencial.jpg";
import hoteleriaEntretenimiento from "../img/hoteleria-entretenimiento.jpg";
import espaciosDeportivos from "../img/espacios-deportivos.jpg";

export const DESIGN_SECTORS = [
  {
    id: 1,
    src: salud,
    alt: "Image 1",
    title: "Salud:",
    description:
      "Contamos con un amplio y variado portafolio de soluciones ideales para espacios hospitalarios y sanitarios.",
  },
  {
    id: 2,
    src: eductation,
    alt: "Image 2",
    title: "Educación:",
    description:
      "Ofrecemos un amplio portafolio de telas, pisos, alfombras y gramas para todos los espacios que conforman un centro educativo.",
  },
  {
    id: 3,
    src: hoteleriaEntretenimiento,
    alt: "Image 3",
    title: "Hoteleria y Entretenimiento:",
    description:
      "Nuestro portafolio de productos y soluciones complementan todas las áreas, desde las habitaciones donde se requiere confort y tranquilidad, hasta las áreas más exigentes expuestas al sol y al agua.",
  },
  {
    id: 4,
    src: corporate,
    alt: "Image 4",
    title: "Corporativo:",
    description:
      "Ofrecer confort, tranquilidad y bienestar en los empleados es necesario para mejorar el clima y la productividad.",
  },
  {
    id: 5,
    src: residencial,
    alt: "Image 5",
    title: "Residencial:",
    description:
      "Nuestra variada gama de diseños, texturas y colores en pisos, le permitirá personalizar su hogar generando ambientes cálidos y seguros.",
  },
  {
    id: 6,
    src: espaciosDeportivos,
    alt: "Image 6",
    title: "Espacios deportivos y terminales de transporte:",
    description:
      "Contamos con una amplia variedad de telas de alta especificación tanto para uso interior como para exteriores, ofreciendo resistencia a la abrasión, el agua y la luz solar.",
  },
];
