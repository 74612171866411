import React from 'react'
import logoCalypso from "../Header/img/logo_white.png"
import "./styles/HeroBanner.scss"

const HeroBanner = () => {
  return (
    <div className="col-12 bg-transparent position-absolute overflow-hidden hero-banner" data-testid="hero-container">
      <img alt="" className='img-fluid logo-calypso mt-md-4' src={logoCalypso} width={300} height="auto" />
    </div>
  )
}

export default HeroBanner