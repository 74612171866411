import PropTypes from "prop-types";
import Form from "./components/Form";

const Contact = ({ id }) => (
  <div id={id} className="my-4 py-4" style={{ backgroundColor: "#929292"}}>
    <div className="container my-4">
      <div className="row">
        <div className="col-6">
          <h2 className="fw-bold text-uppercase text-white mb-5">
            PARA MÁS INFORMACIÓN SOBRE NUESTRO PROYECTO CONTÁCTANOS
          </h2>
        </div>
        <Form />
      </div>
    </div>
  </div>
);

Contact.propTypes = {
  id: PropTypes.string.isRequired,
};

export default Contact;
