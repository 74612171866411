import React from 'react'
import PropTypes from "prop-types";
import "./styles/VideoSection.scss"
import Video from 'components/Video';

const VideoSection = ({ id }) => {
  return (
    <div id={id} className="video-section-container p-md-5">
      <div className="container video-section-sub-container pt-5 pt-md-0 p-md-4 d-lg-flex">
        <div className="col-md-6 col-sm-8 mt-4 container px-xl-5">
          <Video className="img-fluid" fromYoutube>
            <iframe width="100%" height="350" src="https://www.youtube.com/embed/Atz0Q5DPC-A?si=hbHJUgIKoZml6ZHa" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen />
          </Video>
          <h3 className="text-dim-gray">Conoce más sobre nosotros y nuestro equipo en el siguiente video:</h3>
        </div>
        <div className="col-md-6 col-sm-8 mt-4 container px-xl-5">
          <Video className="img-fluid" fromYoutube>
            <iframe width="100%" height="350" src="https://www.youtube.com/embed/P1800wLiAiA?si=1d6rbpaj8rxtk_JY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen />
          </Video>
          <h3 className="text-dim-gray">Conoce nuestra participación en el CTIC en Bogotá.</h3>
        </div>
      </div>
    </div>
  )
}

VideoSection.propTypes = {
  id: PropTypes.string.isRequired,
};

export default VideoSection