import PropTypes from "prop-types";
import Gallery from "./components/Gallery";

const Products = ({ id }) => (
  <div id={id} className="container my-4 py-4">
    <div className="row px-md-5 mx-md-5">
      <div className="col-12">
        <h2 className="fw-bold text-uppercase mb-4">Productos</h2>
      </div>
      <Gallery galleryID="products-ctic" />
    </div>
  </div>
);

Products.propTypes = {
  id: PropTypes.string.isRequired,
};

export default Products;
