import fabrics from "../img/Telas.png"
import floors from "../img/Pisos.jpeg"
import wallCovering from "../img/wallcovering.jpeg"
import installationService from "../img/instalacion.jpeg"


export const PRODUCTS_AND_SERVICES = [
  {
    title: "Telas",
    description: "Contamos con telas de alta especificación que responden a las exigencias técnicas de los mercados de tapicería, haciendo más atractivos los espacios y con una alta calidad que perdura en el tiempo. El diseño, nuestras tecnologías y los tejidos que desarrollamos nos hacen diferentes y uno de los líderes en la industria.",
    img: fabrics,
    link: null
  },
  {
    title: "Pisos",
    description: "El diseño es prioridad para nosotros; por eso, hacemos todo para estar a la vanguardia y sorprender con alfombras, gramas sintética y pisos llenos de diseño, color, confort, sin dejar a un lado lo más importante, calidad, innovación, sostenibilidad y alto desempeño.",
    img: floors,
    link: "https://21327409.fs1.hubspotusercontent-na1.net/hubfs/21327409/Portafolio%20de%20Pisos%202025.pdf"
  },
  {
    title: "Wall Covering",
    description: "¡Deja volar tu imaginación! Ofrecemos una amplia paleta de diseños y colores que brindan personalización e impacto a los espacios. Este producto es desarrollado a la medida de las necesidades, por eso te recomendamos hacer tu solicitud con un experto.",
    img: wallCovering,
    link: null
  },
  {
    title: "Servicio de Instalación",
    description: "La experiencia de nuestros instaladores de piso ha sido reconocida a nivel nacional en los diferentes proyectos que hemos ejecutado por su excelente acabado, tiempos de instalación y pulcritud.",
    img: installationService,
    link: null
  }
]