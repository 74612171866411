import PropTypes from "prop-types";
// import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap"
// import useToggle from "hooks/useToggle";

const ItemDropdown = ({ text, dropDown }) => {
  // const [toggable, toggle] = useToggle();

  return (
    <div>
      <p>ItemDropdown</p>
    </div>
    // <Dropdown className="header-menu-button" isOpen={toggable} toggle={toggle}>
    //   <DropdownToggle color="" className="header-menu-button">
    //     {text}
    //   </DropdownToggle>
    //   <DropdownMenu>
    //     {dropDown.map(({ id, text, href }) => (
    //       <DropdownItem
    //         key={`key_${id}`}
    //         tag="a"
    //         // onClick={handleLinkClick}
    //         href={href}
    //         // target="_blank"
    //         rel="noopener noreferrer"
    //       >
    //         {text}
    //       </DropdownItem>
    //     ))}
    //   </DropdownMenu>
    // </Dropdown>
  );
};

ItemDropdown.propTypes = {
  text: PropTypes.string,
  dropDown: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      text: PropTypes.object,
      href: PropTypes.string,
    })
  ),
};

export default ItemDropdown;
