import PropTypes from "prop-types";

const ErrorMessage = ({
  className = "",
  color = "red",
  weight = "bold",
  children,
}) => (
  <p
    className={`form-error-message form-error-message--color-${color} form-error-message--weight-${weight} ${className}`}
  >
    {children}
  </p>
);

ErrorMessage.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(["red", "black", "white"]),
  weight: PropTypes.oneOf(["bold", "normal"]),
  children: PropTypes.node,
};

export default ErrorMessage;
