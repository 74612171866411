import * as yup from "yup";

export const INTERESTED_PRODUCTS = [
  { value: "Pisos Vinílicos", label: "Pisos Vinílicos" },
  { value: "Tapicerías", label: "Tapicerías" },
  { value: "Wallcovering", label: "Wallcovering" },
  { value: "Alfombras", label: "Alfombras" },
  { value: "Gramas Sintéticas", label: "Gramas Sintéticas" },
  { value: "OTRO", label: "Otro - ¿Cuál?" },
];

export const REQUEST_ENDPOINT_ZAPIER_CTIC =
  "https://hooks.zapier.com/hooks/catch/10364333/bvhg55j/";

const VALIDATION_MESSAGES = {
  required: "Este campo es requerido",
  email: "Debe ser un email valido",
};

export const DEFAULT_VALUES = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  sector: "",
  jobPosition: "",
  message: "",
  privacyPolicy: false,
};

export const VALIDATION_SCHEMA = yup.object({
  firstName: yup.string().required(VALIDATION_MESSAGES.required),
  lastName: yup.string().required(VALIDATION_MESSAGES.required),
  email: yup
    .string()
    .email(VALIDATION_MESSAGES.email)
    .required(VALIDATION_MESSAGES.required),
  phone: yup.string().required(VALIDATION_MESSAGES.required),
  sector: yup.string().required(VALIDATION_MESSAGES.required),
  jobPosition: yup.string().required(VALIDATION_MESSAGES.required),
  message: yup.string().required(VALIDATION_MESSAGES.required),
  privacyPolicy: yup.boolean().oneOf([true], VALIDATION_MESSAGES.required),
});
