import PropTypes from "prop-types";
import usePhotoswipe from "hooks/usePhotoswipe";
import ofice from "./img/oficina.jpg";
import oficeFundation from "./img/oficinas-fundacion.jpg";
import "./styles/description.scss";
import Video from "../../../../../components/Video";

const Description = ({ id, galleryID = "img-test" }) => {
  usePhotoswipe({ galleryID });

  return (
    <div id={id} className="bg-project-description mb-4 pb-4">
      <div className="container">

        <div className="pt-5 pt-md-0 p-md-4 my-5 d-lg-flex align-items-center">
          <div className="col-md-8 col-sm-8 pt-4 container px-md-5 mx-md-5">
            <Video className="img-fluid" fromYoutube>
              <iframe width="100%" height="450" src="https://www.youtube.com/embed/P1800wLiAiA?si=1d6rbpaj8rxtk_JY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen />
            </Video>
          </div>
          <h3 className="text-dim-gray">Conoce nuestra participación en el CTIC en Bogotá.</h3>
        </div>
  
        <div className="row justify-content-center px-md-5 mx-md-5">
          <h2 className="fw-bold text-uppercase mb-4">
            Descripción <br /> Proyecto y Cifras
          </h2>
          <div className="col-md-4 col-sm-12">
            <div className="line-up" />
            <p className="mt-2">
              La Fundación CTIC es una entidad sin ánimo de lucro construida
              gracias a la donación de 2 billones de pesos. Es una construcción
              LEED certificada y zona franca especial permanente. Esta obra fue
              declarada por el Gobierno Nacional como un proyecto nacional
              estratégico para el país.
            </p>
            <p>
              Es una clínica especializada integral en tratamientos para el
              cáncer, con un área de 100.000 m2 de los cuales casi 40.000 m2
              tienen nuestros productos. La primera etapa cuenta con un centro
              de Investigación, hospitalización con 128 habitaciones,
              tratamientos alternativos, medicina nuclear, laboratorio de
              genética y biología molecular, imágenes diagnósticas, auditorio y
              en la segunda etapa se tiene proyectado la construcción de un
              hotel.
            </p>
          </div>
          <div className="col-md-4 col-sm-12">
            <div className="line-up" />
            <p className="mt-2">
              En Calypso Proyectos iniciamos la especificación en el año 2019
              con la firma de Arquitectura Española De La Hoz y Construcciones
              Planificadas, y durante el 2020, 2021 y 2022 llevamos a cabo todo
              el proceso de suministro e instalación de los productos
              especificados.
            </p>
            <p>
              "Nos sentimos muy orgullosos de ser partícipes de este gran
              proyecto que beneficiará a más 7.000 pacientes por año. Gracias a
              que nuestro portafolio de telas y pisos cumple con altas
              especificaciones de desempeño y calidad, podemos decir que somos
              uno de los grandes proveedores de este gran propósito" Claudia
              Cruz, coordinadora de comercial
            </p>
          </div>
          <div className="col-md-4 col-sm-12 pswp-gallery" id={galleryID}>
            <a
              href={oficeFundation}
              data-pswp-width={1920}
              data-pswp-height={1200}
              key={galleryID + "-" + 1}
              target="_blank"
              rel="noreferrer"
            >
              <div
                className="img--description"
                style={{ backgroundImage: `url(${ofice})` }}
              />
            </a>
          </div>
          <div className="row justify-content-center my-4">
            <div className="col-md-3">
              <span className="figure">
                37.000 m<sup>2</sup>
              </span>
              <span className="d-block">De piso vinílico en rollo y LVT</span>
            </div>
            <div className="col-md-3">
              <span className="figure">
                2.050 m<sup>2</sup>
              </span>
              <span className="d-block">De Alfombra Modular</span>
            </div>
            <div className="col-md-3">
              <span className="figure">
                750 m<sup>2</sup>
              </span>
              <span className="d-block">De Wallcovering</span>
            </div>
            <div className="col-md-3">
              <span className="figure">3.400 m</span>
              <span className="d-block">
                De tela vínilica para la tapicería del mobiliario de la
                institución
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Description.propTypes = {
  id: PropTypes.string.isRequired,
};

export default Description;
