import PropTypes from "prop-types";
import Slider from "react-slick";
import { SLIDER_OPTIONS } from "modules/Projects/Ctic/components/Banner/constants";
import { GALLERY } from "./constants";
import "./assets/styles/gallery.scss";
import usePhotoswipe from "hooks/usePhotoswipe";

const Gallery = ({ galleryID }) => {
  usePhotoswipe({ galleryID });

  return (
    <div className="pswp-gallery" id={galleryID}>
      <Slider {...SLIDER_OPTIONS}>
        {GALLERY.map((section, i) => (
          <div key={`key_${i}`} className="parent">
            {section.map(
              ({ id, position, full_img, banner_img, products, ubication }) => (
                <a
                  key={`${galleryID}-${id}`}
                  className="d-contents"
                  href={full_img}
                  data-pswp-width={1920}
                  data-pswp-height={1200}
                  target="_blank"
                  rel="noreferrer"
                >
                  <div
                    className={`position-relative section sec-${position}`}
                    style={{
                      backgroundImage: `url(${banner_img})`,
                    }}
                  >
                    <div className="label upholstery-glassmorphism position-absolute bottom-0 start-0">
                      <p className="text-white fw-bold m-0">{products}</p>
                      <p className="text-white m-0">{ubication}</p>
                    </div>
                  </div>
                </a>
              )
            )}
          </div>
        ))}
      </Slider>
    </div>
  );
};

Gallery.propTypes = {
  galleryID: PropTypes.string.isRequired,
};

export default Gallery;
