import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import ErrorMessage from "components/ErrorMessage";
import {
  DEFAULT_VALUES,
  INTERESTED_PRODUCTS,
  REQUEST_ENDPOINT_ZAPIER_CTIC,
  VALIDATION_SCHEMA,
} from "../../constants";
import SelectCommon from "components/Select";
import SectorsField from "components/Contact/components/Form/components/SectorsField";
import createStoreLead from "./mutation";
import PrivacyPolicyCheckbox from "components/Contact/components/Form/components/PrivacyPolicyCheckbox";

const Form = () => {
  const navigate = useNavigate();
  const {
    control,
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(VALIDATION_SCHEMA),
  });
  const [createLead] = useMutation(createStoreLead);

  const isOther = useWatch({ control, name: "products" }).some(
    ({ value }) => value === "OTRO"
  );

  const handleZap = async (data) => {
    try {
      await axios.post(REQUEST_ENDPOINT_ZAPIER_CTIC, data);
    } catch (e) {
      console.log("handleZap ERROR -> ", e);
    }
  };

  const handleCreateContact = async ({
    privacyPolicy,
    products: rawProducts,
    ...values
  }) => {
    const products = rawProducts.map(({ value }) => value);
    const input = { products, source: "Calypso Proyectos: Ctic", ...values };
    const inputZap = JSON.stringify({
      ...values,
      products,
      date: new Date().toLocaleString(),
    });

    try {
      await createLead({ variables: { input } });
      await handleZap(inputZap);
      navigate("../proyectos/ctic/gracias");
    } catch (e) {
      console.log("ERROR crete contact on CONTACTFORM: ", e);
    }
  };

  return (
    <div className="container">
      <form className="form" onSubmit={handleSubmit(handleCreateContact)}>
        <div className="row">
          <div className="form-group col-12 col-md-6">
            <input
              placeholder="Nombre"
              className="form-control p-2 border border-2 px-4 bg-input"
              id="firstName"
              {...register("firstName")}
            />
            <ErrorMessage color="white">
              {errors.firstName?.message}
            </ErrorMessage>
          </div>
          <div className="form-group col-12 col-md-6">
            <input
              placeholder="Apellido"
              className="form-control p-2 border border-2 px-4 bg-input"
              id="lastName"
              {...register("lastName")}
            />
            <ErrorMessage color="white">
              {errors.lastName?.message}
            </ErrorMessage>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-12 col-md-6">
            <input
              className="form-control p-2 border border-2 px-4 bg-input"
              id="email"
              {...register("email")}
              placeholder="Email"
            />
            <ErrorMessage color="white">{errors.email?.message}</ErrorMessage>
          </div>
          <div className="form-group col-12 col-md-6">
            <input
              className="form-control p-2 border border-2 px-4 bg-input"
              id="phone"
              {...register("phone")}
              placeholder="Teléfono"
            />
            <ErrorMessage color="white">{errors.phone?.message}</ErrorMessage>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-12 col-md-6">
            <input
              className="form-control p-2 border border-2 px-4 bg-input"
              id="city"
              {...register("city")}
              placeholder="Ciudad"
            />
            <ErrorMessage color="white">{errors.city?.message}</ErrorMessage>
          </div>
          <div className="form-group col-12 col-md-6">
            <SectorsField register={register("sector")} />
            <ErrorMessage color="white">{errors.sector?.message}</ErrorMessage>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-12 col-md-6">
            <input
              placeholder="Cargo"
              className="form-control p-2 border border-2 px-4 bg-input"
              id="jobPosition"
              {...register("jobPosition")}
            />
            <ErrorMessage color="white">
              {errors.jobPosition?.message}
            </ErrorMessage>
          </div>
          <div className="form-group col-12 col-md-6">
            <SelectCommon
              name="products"
              placeholder="Selecciona productos de interes"
              control={control}
              options={INTERESTED_PRODUCTS}
              isMulti
            />
            <ErrorMessage color="white">
              {errors.products?.message}
            </ErrorMessage>
          </div>
          {isOther && (
            <div className="form-group col-12 mb-3">
              <input
                placeholder="Otro Producto"
                className="form-control p-2 border border-2 px-4 bg-input"
                id="otherProduct"
                {...register("otherProduct")}
              />
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-12 form-group">
            <textarea
              placeholder="Cuéntanos, ¿en qué podemos ayudarte?"
              className="form-control p-2 border border-2 px-4 bg-input"
              id="message"
              {...register("message")}
            />
            <ErrorMessage color="white">{errors.message?.message}</ErrorMessage>
          </div>
        </div>
        <div className="row">
          <div className="col-12 form-group text-center">
            <PrivacyPolicyCheckbox register={register} errors={errors} />

            <button className="btn btn-outline-light" type="submit">
              {!isSubmitting ? "Enviar" : "Enviando..."}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Form;
