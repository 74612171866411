export const SLIDER_OPTIONS = {
  arrows: false,
  dots: true,
  infinite: true,
  // lazyLoad: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
};

export const STATIC_SLIDER = {
  arrows: false,
  dots: false,
  lazyLoad: true,
  autoplay: false,
  vertical: true,
};
